<template>
  <div>
    <div v-if="!detailStatus">
      <van-empty description="商品不存在">
        <van-button
          round
          type="danger"
          style="width: 120px"
          @click="goBackPage()"
        >
          返回
        </van-button>
      </van-empty>
    </div>
    <div class="ranta-container-0" v-else>
      <div class="ranta-container-0-0">
        <div class="ranta-container-0-0-0">
          <div>
            <div class="head-wrapper">
              <div class="head-menu">
                <div class="iconfont">
                  <van-icon name="arrow-left" size="20" @click="goBackPage()" />
                </div>
                <div class="iconfont">
                  <van-icon name="wap-home-o" size="21" @click="goBackHome()" />
                </div>
              </div>

              <div class="head-menu">
                <div class="iconfont">
                  <van-icon name="share-o" size="20" @click="shareProducts()" />
                </div>
                <div class="iconfont">
                  <van-icon
                    name="apps-o"
                    size="21"
                    @click="dialogMenuVisible = true"
                  />
                </div>
              </div>
            </div>

            <van-swipe
              :autoplay="3000"
              indicator-color="white"
              style="height: 100%"
            >
              <template v-if="productDetailList.pictureList.length > 0">
                <van-swipe-item
                  v-for="(v, k, i) in productDetailList.pictureList"
                  :key="i"
                  @click="handleImageClick(productDetailList.pictureList)"
                >
                  <el-image :src="v.url">
                    <template slot="error">
                      <el-image
                        :src="$defaultImage"
                        style="width: 100%"
                      ></el-image>
                    </template>
                  </el-image>
                </van-swipe-item>
              </template>

              <template v-else>
                <van-swipe-item>
                  <el-image :src="$defaultImage">
                    <template slot="error">
                      <el-image
                        :src="$defaultImage"
                        style="width: 100%"
                      ></el-image>
                    </template>
                  </el-image>
                </van-swipe-item>
              </template>
            </van-swipe>
          </div>

          <!-- 价格 -->
          <div>
            <div>
              <div class="m-base-info">
                <div class="m-title-block">
                  <div class="goods-title u-base-info-row">
                    <div class="goods-title__tags"></div>
                    <div class="goods-title__box">
                      <div class="goods-title__main">
                        <span class="goods-title__main-text">
                          {{ productDetailList.productName }}</span
                        >
                        <div
                          style="
                            color: #8c8c8c;
                            font-size: 14px;
                            padding: 5px 0px 5px 0px;
                          "
                        >
                          {{ productDetailList.productSubtitle }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="m-price-block">
                  <div class="goods-price u-base-info-row">
                    <div class="goods-price__current">
                      <span
                        style="font-weight: 500"
                        v-html="$decimalPrice(productDetailList.price)"
                      ></span>

                      <!-- 原价 -->
                      <div
                        class="goods-price__origin u-base-info-row"
                        v-if="productDetailList.parValue"
                      >
                        <div
                          class="goods-price__origin-price"
                          style="
                            color: #c4c7cf;
                            font-weight: 400;
                            font-size: 0.8125rem;
                            margin-left: 5px;
                          "
                        >
                          ￥{{ productDetailList.parValue }}
                        </div>
                      </div>

                      <div style="margin-left: 10px">
                        <!-- 标签 -->
                        <div style="position: absolute; top: 2px; right: 0">
                          <template v-if="productDetailList.orderTakeTime">
                            <span class="morder-time"
                              >订单耗时：{{
                                productDetailList.orderTakeTime
                              }}</span
                            >
                          </template>

                          <template v-else>
                            <van-tag
                              type="primary"
                              size="medium"
                              style="border-radius: 5px"
                            >
                              {{ productDetailList.productTypeText }}
                            </van-tag>
                          </template>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- 等级差价展示 -->
                <div
                  v-if="setting.levelPrice"
                  class="svipCon acea-row row-between-wrapper skeleton-rect"
                  @click="openLevelVisible"
                >
                  <div class="acea-row row-between-wrapper">
                    <img
                      style="width: 24px; height: 24px; margin-right: 5px"
                      src="@/assets/level_icon.png"
                      draggable="false"
                    />
                    <div>提升等级 享受更低折扣</div>
                  </div>
                  <div class="svipBtn">立即升级</div>
                </div>

                <!-- 价格展示 -->
                <div>
                  <div class="detail_label acea-row row-between-wrapper">
                    <div style="color: #aaa">
                      商品编号：{{ productDetailList.id }}
                    </div>

                    <div
                      class="skeleton-rect"
                      v-if="productDetailList.inventoryState"
                    >
                      {{ productDetailList.inventoryState }}
                    </div>

                    <div
                      class="skeleton-rect"
                      v-if="productDetailList.salsCount"
                    >
                      已售:
                      <span style="color: rgb(76, 175, 80)">
                        {{ productDetailList.salsCount }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <!-- <div class="mgoods_item">
                <div
                  style="position: relative; display: flex; flex: 1 1 0%; overflow: hidden; box-sizing: border-box; flex-direction: row; margin: 0px; padding-left: 0px; padding-right: 0px; width: fit-content; height: auto; -webkit-box-orient: horizontal; -webkit-box-direction: normal;">
                  <div
                    style="position: relative; display: flex; flex-shrink: 0; flex-grow: 0; font-size: 14px; visibility: visible; place-self: flex-start; margin: 0px; width: 49px; color: rgb(153, 153, 153); height: auto; justify-content: flex-start; align-items: center; overflow: hidden; max-width: none; -webkit-box-pack: start; -webkit-box-align: center;">
                    <span
                      style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap; line-height: 17px;">参数</span>
                  </div>
                  <div class="mgoods_item_content">
                    <span style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap; line-height: 17px;">
                      生产日期生产许可证编号...
                    </span>
                  </div>
                </div>
                <div
                  style="position: relative; display: flex; flex-shrink: 0; flex-grow: 0; margin: 0px -3.25px 0px 3px; font-size: 13px; visibility: visible; place-self: center flex-end; width: 13px; color: rgb(204, 204, 204); height: 13px; justify-content: flex-start; align-items: center; overflow: hidden; max-width: none; -webkit-box-pack: start; -webkit-box-align: center;">
                  <span class="taobao-iconfont">큚</span>
                </div>
              </div>
 -->

              <div style="margin-top: 8px" v-if="productDetailList.skuList">
                <div
                  v-if="productDetailList.skuList.length > 0"
                  style="
                    align-items: center;
                    background: #fff;
                    display: flex;
                    padding: 10px 3.2vw;
                    width: 100vw;
                  "
                >
                  <span
                    style="
                      color: #999;
                      flex: 0 0 auto;
                      font-size: 3.2vw;
                      margin-right: 4.8vw;
                    "
                    >商品规格</span
                  >
                  <div
                    class="pay-box"
                    style="flex: 1; overflow-x: scroll; white-space: nowrap"
                  >
                    <div class="mobile-skuItemWrapper">
                      <div
                        class="mobile-skuItem"
                        v-for="(v, k, i) in productDetailList.skuList"
                        :key="i"
                        :class="{ sku_select: selectSkuId === v.productId }"
                        @click="selectSkuId = v.productId"
                      >
                        <div @click="toProductDetails(v.productId)">
                          <img
                            class="mobile-skuIcon"
                            :src="v.productImg"
                            style="opacity: 1; object-fit: contain"
                          />
                          <span class="mobile-skuValueName">
                            {{ v.skuKeyName }}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="channel" v-if="productDetailList.channelTagsList">
                <span class="channel-title">销售渠道：</span>
                <div class="channel-content">
                  <div
                    class="channel_item"
                    v-for="(v, k, i) in productDetailList.channelTagsList"
                    :key="i"
                  >
                    <img
                      src="../../assets/correct.png"
                      class="channel_item_icon"
                    />
                    {{ v }}
                  </div>

                  <!----><!----><!---->
                </div>
              </div>
              <div
                style="
                  background: #fff;
                  text-align: center;
                  border-top: 1px solid #f5f5f5;
                "
              >
                <div style="padding: 10px; font-size: 12px; color: #969799">
                  <van-row gutter="10">
                    <van-col span="6">
                      <div>
                        <img src="@/assets/image/icon_zheng.svg" width="25px" />
                        <span class="icon_name">正品保证</span>
                      </div>
                    </van-col>
                    <van-col span="6">
                      <div>
                        <img
                          src="@/assets/image/icon_shouhou.svg"
                          width="25px"
                        />
                        <span class="icon_name">品质溯源</span>
                      </div>
                    </van-col>
                    <van-col span="6">
                      <div>
                        <img src="@/assets/image/icon_jisu.svg" width="25px" />
                        <span class="icon_name">专属客服</span>
                      </div>
                    </van-col>

                    <van-col span="6">
                      <div>
                        <img
                          src="@/assets/image/icon_anquan.svg"
                          width="25px"
                        />
                        <span class="icon_name">平台保证</span>
                      </div>
                    </van-col>
                  </van-row>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <div>
          <!-- 商品详情 -->
          <div>
            <div>
              <div class="goods-price-intro">
                <div class="title">
                  <div class="white-block">商品详情</div>
                </div>
              </div>
              <div style="background-color: #ffffff">
                <div>
                  <van-empty
                    description="暂无商品描述~"
                    v-if="!productDetailList.productDescription"
                  />
                  <div class="cap-richtext" v-else>
                    <span v-html="productDetailList.productDescription"></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 提交订单弹出框 -->
    <van-popup
      v-model="orderPopupStatus"
      position="bottom"
      :style="{ height: '80%' }"
    >
      <section class="aui-scrollView">
        <!-- 收货地址 -->
        <div v-if="productDetailList.productType === '2'">
          <van-contact-card
            type="add"
            add-text="添加收货地址"
            @click="onAddRess"
            v-if="!addRessConfigVisible"
          />

          <div
            v-else
            class="van-address-item address_class"
            @click="dialogsAddressVisible = true"
          >
            <div class="van-cell van-cell--borderless">
              <div>
                <div>
                  <div style="font-size: 18px"></div>
                  <span>
                    <div class="van-address-item__name">
                      {{ addRessConfig.name }} {{ addRessConfig.tel }}
                    </div>
                    <div class="van-address-item__address">
                      {{ addRessConfig.address }}
                    </div>
                  </span>
                </div>
              </div>
              <i class="van-icon van-icon-edit van-address-item__edit"></i>
            </div>
          </div>
        </div>

        <div>
          <div style="padding: 15px 10px">
            <van-card
              :price="productDetailList.price"
              :title="productDetailList.productName"
              :thumb="productDetailList.productImg"
            />
          </div>

          <van-form>
            <van-cell-group inset>
              <orderParam
                :orderParams="productDetailList.orderParams"
                v-model="productDetailList.orderParams"
              >
              </orderParam>

              <!-- 区间 -->
              <template v-if="productDetailList.dealQuantityType === 1">
                <van-field name="stepper" label="购买数量">
                  <template #input>
                    <van-stepper
                      input-width="100px"
                      v-model="orderForm.quantity"
                      :min="productDetailList.dealQuantityMin"
                      :max="productDetailList.dealQuantityMax"
                    />
                  </template>
                </van-field>
              </template>

              <!-- 自定义 -->
              <template v-if="productDetailList.dealQuantityType === 2">
                <van-field name="stepper" label="购买数量">
                  <template #input>
                    <el-select
                      v-model="orderForm.quantity"
                      placeholder="请选择"
                      size="mini"
                    >
                      <el-option
                        v-for="item in dealQuantityArray(
                          productDetailList.dealQuantityArray
                        )"
                        :key="item"
                        :label="item"
                        :value="item"
                      >
                      </el-option>
                    </el-select>
                  </template>
                </van-field>
              </template>
            </van-cell-group>

            <div style="margin: 16px">
              <van-button
                round
                block
                type="danger"
                native-type="submit"
                @click="createOrder()"
              >
                提交订单￥{{
                  $calculateProductPrice(
                    orderForm.quantity,
                    productDetailList.price,
                    productDetailList.orderParams
                  )
                }}
              </van-button>
            </div>
            <div style="margin: 16px">
              <van-button
                round
                block
                native-type="submit"
                @click="orderPopupStatus = false"
              >
                取消
              </van-button>
            </div>
          </van-form>
        </div>
      </section>
    </van-popup>

    <!-- 收货地址 -->
    <van-popup v-model="dialogsAddressVisible" round position="bottom">
      <van-address-list
        v-model="orderForm.addressId"
        :list="addressList"
        default-tag-text="默认"
        @add="toShouHupDiZhi"
        @click-item="selectAddress"
      />
    </van-popup>

    <el-dialog
      destroy-on-close
      append-to-body
      :visible.sync="levelVisible"
      class="el_dialog_radius"
      width="90%"
      title="等级价格"
      custom-class="centered-dialog"
    >
      <div>
        <div style="margin-bottom: 20px">您当前的等级：{{ levelName }}</div>
        <el-table :data="levelData" border style="width: 100%">
          <el-table-column prop="levelName" label="等级" align="center">
          </el-table-column>
          <el-table-column prop="price" label="价格" align="center">
          </el-table-column>
        </el-table>

        <router-link to="/userlevel">
          <div style="padding: 15px">
            <van-button type="info" round block>立即升级</van-button>
          </div>
        </router-link>
      </div>
    </el-dialog>

    <van-dialog v-model="qrcodeVisible" confirm-button-text="关闭">
      <div>
        <vue-canvas-poster
          v-if="!posterImg"
          :painting="painting"
          @success="success"
        >
        </vue-canvas-poster>

        <img v-else :src="posterImg" style="width: 100%" />
        <!-- 提示 -->
        <div style="text-align: center; font-size: 12px">
          温馨提示：长按图片保存到本地
        </div>
      </div>
    </van-dialog>

    <van-goods-action safe-area-inset-bottom>
      <van-goods-action-icon
        icon="shop-o"
        text="首页"
        @click="toIndex"
        class="custom-icon"
      />

      <van-goods-action-icon
        v-if="collectStatus"
        icon="like"
        text="已收藏"
        color="#FF638C"
        class="custom-icon"
      />

      <van-goods-action-icon
        v-else
        icon="like-o"
        text="收藏"
        @click="collectProducts"
        class="custom-icon"
      />

      <van-goods-action-icon
        icon="service"
        text="客服"
        @click="lianxikefu"
        class="custom-icon"
      />

      <!-- 直冲购买 -->
      <van-goods-action-button
        type="danger"
        text="立即购买"
        @click="purchaseGoods"
      />
    </van-goods-action>

    <!-- 搜索辅助菜单 -->
    <van-popup
      v-model="dialogMenuVisible"
      position="bottom"
      round
      :style="{ height: '30%' }"
    >
      <div style="margin-top: 10px">
        <van-grid :column-num="4" :border="false">
          <van-grid-item icon="home-o" text="商城首页" to="/mindex" />

          <van-grid-item icon="apps-o" text="商品分类" to="/cate" />

          <van-grid-item icon="user-o" text="我的账户" to="/my" />

          <van-grid-item icon="orders-o" text="我的订单" to="/order" />

          <van-grid-item icon="bullhorn-o" text="商城公告" to="/sysNotice" />

          <van-grid-item icon="service-o" text="联系客服" to="/contact" />
        </van-grid>

        <div style="padding: 15px">
          <van-button
            type="default"
            size="large"
            round
            @click="dialogMenuVisible = false"
            >关闭</van-button
          >
        </div>
      </div>
    </van-popup>

    <!-- 添加收货地址 -->
    <van-popup
      v-model="dialogVisible"
      position="bottom"
      :style="{ height: '100%', backgroundColor: '#f7f8fa' }"
    >
      <van-nav-bar title="收货地址" />

      <div style="padding: 15px">
        <van-form>
          <van-field
            v-model="ruleForm.deliveryPerson"
            name="姓名"
            label="姓名"
            placeholder="请输入姓名"
            :rules="[{ required: true, message: '请输入姓名' }]"
          />

          <van-field
            v-model="ruleForm.deliveryPhone"
            name="联系电话"
            label="联系电话"
            placeholder="联系电话"
            :rules="[{ required: true, message: '请输入联系电话' }]"
          />

          <van-field
            is-link
            readonly
            v-model="fieldValue"
            label="地区"
            placeholder="请选择所在地区"
            @click="dialogsRegionVisible = true"
          />

          <van-field
            v-model="ruleForm.deliveryAddress"
            name="详细地址"
            label="详细地址"
            placeholder="详细地址"
            :rules="[{ required: true, message: '请输入详细地址' }]"
          />

          <div style="margin: 16px 0px">
            <van-button round block type="danger" @click="onSubmit()">
              提交
            </van-button>
          </div>

          <div style="margin: 16px 0px">
            <van-button round block @click="dialogVisible = false">
              取消
            </van-button>
          </div>
        </van-form>
      </div>
    </van-popup>

    <van-popup v-model="dialogsRegionVisible" round position="bottom">
      <van-cascader
        v-model="cascaderValue"
        title="请选择所在地区"
        :options="treeList"
        :field-names="fieldNames"
        @close="dialogsRegionVisible = false"
        @finish="onFinish"
      />
    </van-popup>
  </div>
</template>
<script>
import { mapGetters, mapState } from "vuex";
import { Toast } from "vant";
import { Dialog } from "vant";
import { ImagePreview } from "vant";
import { addCollectProducts, addBrowseProducts } from "@/api/product";
import orderParam from "@/components/mOrderParam.vue";
import { getToken } from "@/utils/auth";
import { queryProductDetail, cross } from "@/api/index";

import { queryProductLevelPrice } from "@/api/product";

import { getAddressList, regionTree, address } from "@/api/useraddress";

export default {
  components: {
    orderParam,
  },
  data() {
    return {
      cascaderValue: undefined,
      fieldNames: {
        text: "regionName",
        value: "id",
        children: "children",
      },
      fieldValue: undefined,
      treeList: [],
      dialogVisible: false,
      dialogsRegionVisible: false,
      ruleForm: {
        deliveryPerson: undefined,
        deliveryPhone: undefined,
        deliveryAddress: undefined,

        //省
        regionProvince: undefined,
        regionProvinceName: undefined,

        regionCity: undefined,
        regionCityName: undefined,

        regionCountry: undefined,
        regionCountryName: undefined,
      },

      addressId: undefined,
      addRessConfigVisible: false,
      addRessConfig: {},
      dialogsAddressVisible: false,
      addressList: [],
      //=========================
      dialogMenuVisible: false,
      posterImg: undefined,
      levelData: [],
      levelVisible: false,
      selectSkuId: undefined,
      collectStatus: false,
      qrcodeVisible: false,
      painting: {
        width: "550px",
        height: "876px",
        background: require("@/assets/image/1703344720899.png"),
        views: [
          {
            type: "image",
            url: "",
            css: {
              top: "20px",
              left: "35px",
              borderRadius: "10px",
              width: "480px",
              height: "480px",
            },
          },

          {
            type: "text",
            text: ``,
            css: {
              top: "520px",
              left: "36px",
              width: "480px",
              color: "#000000",
              fontSize: "22px",
            },
          },
          {
            type: "text",
            text: `2`,
            css: {
              top: "730px",
              left: "50px",
              width: "500px",
              color: "#000000",
              fontSize: "26px",
            },
          },
          {
            type: "qrcode",
            content: "",
            css: {
              bottom: "90px",
              right: "76px",
              color: "#000",
              background: "#fff",
              width: "100px",
              height: "100px",
              borderWidth: "5px",
              borderColor: "#fff",
            },
          },
          {
            type: "text",
            text: `微信扫码识别`, // 后端返回路径，这里特别坑，想象不到的bug，oss或者自己服务都允许跨域了，但在手机上还是会有出现cors跨域问题，可能是缓存问题，我们在路径上加个时间戳就好了0.0
            css: {
              bottom: "50px",
              right: "72px",
              color: "rgba(255,255,255,255)",
              fontSize: "18px",
            },
          },
          {
            type: "image",
            url: "",
            css: {
              width: "120px",
              height: "31px",
            },
          },
          // ....
        ],
      },
      popupTipVisible: false,

      kefuStatus: false,
      orderPopupStatus: false,
      productDetailList: [],
      orderForm: {
        //收件人姓名
        // deliveryPerson: null,
        //县编码
        // deliveryCountry: "",
        //省编码
        // deliveryProvince: null,
        //市编码
        // deliveryCity: null,
        //省名称
        // deliveryProvinceName: "",
        //城市名称
        // deliveryCityName: "",
        //地址
        // deliveryAddress: "",
        //手机号
        // deliveryPhone: "",
        //运费模版
        // deliveryConfigId: 1,
        // deliveryConfig: "",
        //成交平台 0:web 1:ios 2:android 3:wx
        // dealPlatform: 1,
        //备注
        // deliveryRemark: "",
        //邮编
        // deliveryPostcode: "",
        //充值账号
        rechargeAccount: "",
        //充值数量
        quantity: undefined,
        //商品id
        productId: null,
        stockId: undefined,
        addressId: undefined,
      },

      orderParams: [],
      newProductId: undefined,

      //详情状态 true为正常 false
      detailStatus: true,
    };
  },
  computed: {
    ...mapGetters({}),
    ...mapState({
      productDetail: (state) => state.index.productDetail,
      orderId: (state) => state.order.orderId,
      webSetting: (state) => state.index.webSetting,
      setting: (state) => state.index.setting,
      levelName: (state) => state.user.levelName,
    }),
  },

  watch: {
    "$route.params.id": function (newId, oldId) {
      // 检测到id参数变动时执行的操作
      if (newId !== oldId) {
        // 执行刷新参数的操作
        this.refreshData();
      }
    },
  },
  created() {
    // 执行刷新参数的操作
    this.refreshData();
  },

  methods: {
    dealQuantityArray(value) {
      // this.orderForm.quantity = value[0];
      return value.split(",");
    },

    /**
     * 新增收货地址
     */
    onSubmit() {
      new Promise((resolve, rejust) => {
        address(this.ruleForm)
          .then((res) => {
            console.log(res);
            if (res.status == 0) {
              Toast.success({
                message: "添加成功",
              });
              this.dialogVisible = false;
              this.queryAddressList();
            }
            resolve();
          })
          .catch((error) => {
            rejust(error);
          });
      });
    },

    //选择地区
    onFinish({ selectedOptions }) {
      //省
      this.ruleForm.regionProvince = selectedOptions[0].id;
      this.ruleForm.regionProvinceName = selectedOptions[0].regionName;

      this.ruleForm.regionCity = selectedOptions[1].id;
      this.ruleForm.regionCityName = selectedOptions[1].regionName;

      this.ruleForm.regionCountry = selectedOptions[2].id;
      this.ruleForm.regionCountryName = selectedOptions[2].regionName;

      this.dialogsRegionVisible = false;
      this.fieldValue = selectedOptions
        .map((option) => option.regionName)
        .join("/");
    },

    getRegionTree() {
      new Promise((resolve, rejust) => {
        regionTree({})
          .then((res) => {
            this.treeList = res.result;
            resolve();
          })
          .catch((error) => {
            rejust(error);
          });
      });
    },

    queryAddressList() {
      new Promise((resolve, rejust) => {
        getAddressList({})
          .then((res) => {
            this.addressList = res.result;
            resolve();
          })
          .catch((error) => {
            rejust(error);
          });
      });
    },

    /**
     * 选择收货地址
     */
    onAddRess() {
      this.queryAddressList();

      //查询收货地址
      this.getRegionTree();

      this.dialogsAddressVisible = true;
    },

    //添加收货地址
    toShouHupDiZhi() {
      this.dialogVisible = true;
    },

    /**
     * 选择地址
     */
    selectAddress(e) {
      console.log(this.addressId);
      this.addRessConfig = e;
      //关闭地址弹框
      this.dialogsAddressVisible = false;

      //已选择确认
      this.addRessConfigVisible = true;
    },

    refreshData() {
      this.newProductId = this.$route.params.id;

      this.selectSkuId = this.$route.params.id;

      //获取获取商品详情
      this.getProductDetail();
      //进来就浏览商品
      this.browseProducts();
    },

    //获取商品详情
    async getProductDetail() {
      let data = { product_id: this.newProductId };

      const response = await queryProductDetail(data);

      if (response.result) {
        this.productDetailList = response.result;

        this.collectStatus = this.productDetailList.collectStatus;
        if (this.productDetailList.popupTip) {
          Dialog.alert({
            title: "充值提示",
            message: this.productDetailList.popupTip,
            theme: "round-button",
          }).then(() => {});
        }

        //设置网站标题
        document.title = this.productDetailList.productName;

        //赋值默认参数
        if (this.productDetailList.dealQuantityType === 1) {
          this.orderForm.quantity = this.productDetailList.dealQuantityMin;
        }

        if (this.productDetailList.dealQuantityType === 2) {
          let data = this.productDetailList.dealQuantityArray.split(",");

          this.orderForm.quantity = data[0];
        }
      } else {
        this.detailStatus = false;
      }
    },

    /**
     * 浏览商品
     */
    async browseProducts() {
      const hasToken = getToken();
      if (hasToken) {
        let data = { productId: this.newProductId };
        await addBrowseProducts(data);
      }
    },

    /**
     * 收藏商品
     */
    collectProducts() {
      let data = { productId: this.newProductId };
      new Promise((resolve, rejust) => {
        addCollectProducts(data)
          .then(() => {
            this.collectStatus = true;
            resolve();
          })
          .catch((error) => {
            rejust(error);
          });
      });
    },

    /**
     * 等级差价展示
     */
    openLevelVisible() {
      let data = { productId: this.newProductId };
      queryProductLevelPrice(data)
        .then((res) => {
          this.levelData = res.result;
          this.levelVisible = true;
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          // 无论成功失败都会执行的逻辑
        });
    },

    //联系客服
    lianxikefu() {
      this.$router.push("/contact");
    },

    /**
     * 点击放大图片
     */
    handleImageClick(imgList) {
      const resultList = [];
      for (const iterator of imgList) {
        resultList.push(iterator.url);
      }
      ImagePreview(resultList);
    },

    /**
     * 分享商品
     */
    shareProducts() {
      // this.qrcodeVisible = true;
      // this.painting.views[0].url =
      //   this.productDetailList.pictureList[0].url +
      //   "?time=" +
      //   new Date().getTime();
      // this.painting.views[1].text = this.productDetailList.productName;
      // this.painting.views[2].text =
      //   "￥" + this.productDetailList.price.toString();
      // this.painting.views[3].content = window.location.href;

      let imagePath = this.productDetailList.pictureList[0].url;
      cross({ imagePath })
        .then((res) => {
          this.qrcodeVisible = true;

          this.painting.views[0].url = res.result;

          this.painting.views[1].text = this.productDetailList.productName;
          this.painting.views[2].text =
            "￥" + this.productDetailList.price.toString();
          this.painting.views[3].content = window.location.href;
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          // 无论成功失败都会执行的逻辑
        });
    },

    // 海报生成
    success(src) {
      this.posterImg = src;
    },

    //提交订单,弹出框输入账号
    purchaseGoods() {
      this.orderPopupStatus = true;
    },

    //提交订单
    createOrder() {
      //判断充值账号是否为空
      if (
        this.orderForm.productType == "1" &&
        this.orderForm.rechargeAccount == ""
      ) {
        this.$message({
          message: "请填写充值账号",
        });
        return false;
      }

      this.orderForm.productId = this.newProductId;
      this.orderForm.orderParams = this.productDetailList.orderParams;
      this.orderForm.stockId = this.productDetailList.productStock.id;

      this.$store
        .dispatch("order/create", this.orderForm)
        .then(() => {
          this.$router.push({
            path: "/payment",
            query: { orderId: this.orderId },
          });
        })
        .catch(() => {
          // this.$message({
          //     message: '提交失败！',
          //     type: 'error'
          // });
        });
    },

    toIndex() {
      this.$router.push("/mindex");
    },

    //跳转商品详情界面
    toProductDetails(id) {
      // this.$router.push({
      //   path: "product_detail",
      //   query: { product_id: id },
      // });
      this.$router.push(`/product_detail/${id}`);
    },

    // touchEnd() {
    //   //手指离开
    //   clearTimeout(this.timer);
    // },

    // touchStart() {
    //   //手指触摸
    //   clearTimeout(this.timer); //再次清空定时器，防止重复注册定时器
    //   this.timer = setTimeout(() => {
    //     this.downloadIamge(this.qrcodeUrl64, "pic");
    //   }, 1000);
    // },

    /**
     * 返回上一页
     */
    goBackPage() {
      this.$router.go(-1);
    },

    /**
     * 返回主页
     */
    goBackHome() {
      this.$router.push("/mindex");
    },
  },
};
</script>
<style>
.ranta-container-0 {
  /* display: -webkit-box; */
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  width: 100%;
  padding-bottom: 85px;
  background-color: #f7f8fa;
  background-size: 100% auto;
  color: #323233;
}

.ranta-container-0-0 {
  /* display: -webkit-box; */
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
}

.ranta-container-0-0-0 {
  /* display: -webkit-box; */
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
}

.image-block.is-inited {
  width: 100%;
  height: 100%;
  /* display: flex;
    align-items: center;
    justify-content: center; */
  /* overflow: hidden; */
}

.image-block {
  position: relative;
  background-color: #f7f8fa;
  /* height: 375px; */
}

.image-block-wrap {
  position: relative;
  z-index: 1;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.image-swipe {
  height: 100%;
}

.tee-swipe {
  position: relative;
  /* -webkit-user-select: none; */
  user-select: none;
  overflow: hidden;
}

.tee-swipe__track {
  display: flex;
}

.tee-swiper-item {
  /* -webkit-flex-shrink: 0; */
  flex-shrink: 0;
}

.image-swipe-item {
  max-width: 100%;
  object-fit: contain;
}

.m-base-info {
  padding: 0 16px 12px;
  background: #fff;
}

.m-price-block {
  position: relative;
  background-color: #fff;
  padding-top: 12px;
}

.u-base-info-row {
  position: relative;
  width: 100%;
  line-height: 18px;
}

.u-base-info-row {
  font-size: 13px;
}

.goods-price {
  position: relative;
  text-align: left;
  /* display: -webkit-box; */
  /* display: -webkit-flex; */
  display: flex;
  -webkit-box-align: center;
  /* -webkit-align-items: center; */
  align-items: center;
  /* -webkit-flex-wrap: wrap; */
  flex-wrap: wrap;
}

.goods-price__current {
  /* display: -webkit-box;
	    display: -webkit-flex; */
  display: flex;
  -webkit-box-align: end;
  /* -webkit-align-items: flex-end; */
  align-items: flex-end;
  margin-right: 8px;
  vertical-align: middle;
  font-size: 16px;
  color: var(--price, #323233);
}

.goods-price__current-label {
  display: inline-block;
  font-size: 16px;
  line-height: 22px;
  margin-right: 2px;
  font-weight: 700;
  position: relative;
}

.goods-price__current-price {
  display: inline-block;
  vertical-align: middle;
  font-size: 22px;
  line-height: 24px;
  font-weight: bolder;
}

.u-base-info-row {
  position: relative;
  width: 100%;
  line-height: 18px;
}

.u-base-info-row {
  font-size: 13px;
}

.goods-price__origin {
  /* position: relative; */
  text-align: left;
  display: block;
  /* font-size: 12px; */
  color: #969799;
  /* line-height: 16px; */
  margin: 4px 0 0;
}

.goods-price__origin-label {
  margin-right: 4px;
}

.goods-price__origin-price {
  display: inline-block;
  text-decoration: line-through;
}

.m-title-block {
  position: relative;
  background-color: #fff;
  padding-top: 12px;
}

.u-base-info-row {
  position: relative;
  width: 100%;
  font-size: 13px;
  line-height: 18px;
}

.goods-title__tags:empty {
  margin-bottom: 0;
}

.goods-title__box {
  position: relative;
  /* display: -webkit-box;
	    display: -webkit-flex; */
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  /* -webkit-flex-direction: row; */
  flex-direction: row;
  -webkit-box-pack: justify;
  /* -webkit-justify-content: space-between; */
  justify-content: space-between;
  -webkit-box-align: center;
  /* -webkit-align-items: center; */
  align-items: center;
  min-height: 26px;
}

.goods-title__main {
  -webkit-box-flex: 1;
  /* -webkit-flex: 1; */
  flex: 1;
  width: 100%;
  display: inline-block;
  vertical-align: middle;
  line-height: 20px;
  font-size: 0;
  font-weight: 500;
  text-align: left;
  word-break: break-all;
  word-wrap: break-word;
}

.goods-title__main-text {
  display: inline;
  vertical-align: middle;
  margin-right: 4px;
  line-height: 20px;
  font-size: 16px;
  color: #333333;
  /* font-weight: 600; */
}

.goods-title__more-wrapper {
  position: relative;
  height: 100%;
  width: 24px;
  min-height: 36px;
}

.goods-title__more {
  position: absolute;
  left: 0;
  top: 50%;
  /* -webkit-transform: translateY(-50%); */
  transform: translateY(-50%);
  /* display: -webkit-box;
	    display: -webkit-flex; */
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  /* -webkit-flex-direction: column; */
  flex-direction: column;
  -webkit-box-pack: justify;
  /* -webkit-justify-content: space-between; */
  justify-content: space-between;
  -webkit-box-align: center;
  /* -webkit-align-items: center; */
  align-items: center;
  color: #969799;
}

.goods-title__more-item-word {
  margin-left: 2px;
  font-size: 12px;
  line-height: normal;
  white-space: nowrap;
}

.van-cell--center {
  /* -webkit-align-items: center; */
  align-items: center;
}

.van-cell {
  position: relative;
  /* display: -webkit-flex; */
  display: flex;
  box-sizing: border-box;
  width: 100%;
  padding: 10px 16px;
  padding: var(--cell-vertical-padding, 10px)
    var(--cell-horizontal-padding, 16px);
  /* font-size: 14px; */
  font-size: var(--cell-font-size, 14px);
  /* line-height: 24px; */
  line-height: var(--cell-line-height, 24px);
  /* color: #323233; */
  color: var(--cell-text-color, #323233);
  /* background-color: #fff; */
  background-color: var(--cell-background-color, #fff);
}

.van-cell__title:empty {
  display: none;
}

/* .van-cell__title {
  flex: 1;
} */

/* .van-cell__value {
  flex: 1;
} */

.van-cell__value {
  overflow: hidden;
  /* text-align: right; */
  vertical-align: middle;
  /* color: #969799; */
  color: var(--cell-value-color, #969799);
}

.guarantee-bar__info {
  height: 24px;
  /* display: -webkit-box;
	    display: -webkit-flex; */
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.guarantee-bar__logo.short-green {
  width: 59px;
  height: 16px;
}

.guarantee-bar__desc {
  /* display: -webkit-box;
	    display: -webkit-flex; */
  display: flex;
  /* -webkit-flex-wrap: wrap; */
  flex-wrap: wrap;
  font-size: 12px;
  height: 16px;
  line-height: 16px;
  margin-top: 6px;
  color: #969799;
  overflow: hidden;
}

.goods-stock-block {
  margin-top: 8px;
  font-size: 14px;
}

.goods-stock-block .stock-cell__main {
  display: flex;
  color: #323233;
}

.goods-stock-block .stock-cell__title {
  /* -webkit-flex-shrink: 0; */
  flex-shrink: 0;
  margin-right: 12px;
  color: #969799;
}

.goods-stock-block .goods-stock {
  /* display: -webkit-box;
	    display: -webkit-flex; */
  display: flex;
  -webkit-box-pack: justify;
  /* -webkit-justify-content: space-between; */
  justify-content: space-between;
  width: 100%;
}

.goods-stock-block .goods-stock .stock {
  /* display: -webkit-box;
	    display: -webkit-flex; */
  display: flex;
  color: #969799;
}

.goods-stock-block .goods-stock .stock .stock-content {
  /* display: -webkit-box;
	    display: -webkit-flex; */
  display: flex;
  -webkit-box-align: center;
  /* -webkit-align-items: center; */
  align-items: center;
}

.goods-stock-block .goods-stock .stock {
  /* display: -webkit-box;
	    display: -webkit-flex;
	    display: flex; */
  color: #969799;
}

.group-block {
  margin-top: 8px;
}

.service-block .sc__main--ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.service-block .sc__main {
  /* display: -webkit-box;
	    display: -webkit-flex;
	    display: flex; */
  color: #323233;
}

.service-block .sc__title {
  /* -webkit-flex-shrink: 0; */
  flex-shrink: 0;
  margin-right: 12px;
  color: #969799;
}

.cap-richtext {
  padding: 10px;
  overflow-x: hidden;
}

.cap-richtext img {
  width: 100%;
  height: auto;
  max-width: 100%;
}

.goods-price-intro {
  position: relative;
  box-sizing: border-box;
  font-size: 12px;
  color: #969799;
  overflow: hidden;
  background-color: #ffffff;
  margin-top: 8px;
}

.goods-price-intro .title {
  position: relative;
  margin: 0 16px;
  text-align: center;
  color: #323233;
  line-height: 44px;
  height: 44px;
  font-size: 14px;
  font-weight: 500;
}

.goods-price-intro .white-block {
  display: inline-block;
  height: 100%;
  background: #fff;
  padding: 0 8px;
  position: absolute;
  /* z-index: 1; */
  /* -webkit-transform: translateX(-50%); */
  transform: translateX(-50%);
  font-size: 16px;
  font-weight: 600;
}

.goods-price-intro .icon,
.goods-price-intro .icon-rotate {
  display: inline-block;
  position: relative;
  top: 2px;
  font-size: 16px;
  color: #dcdee0;
  /* -webkit-transform: rotate(90deg);
	    transform: rotate(90deg);
	    -webkit-transition: -webkit-transform .3s;
	    transition: -webkit-transform .3s;
	    transition: transform .3s;
	    transition: transform .3s,-webkit-transform .3s; */
}

.goods-price-intro .content-show {
  height: auto;
  margin: 0 16px 16px;
}

.goods-price-intro .content {
  background-color: #f7f8fa;
  border-radius: 4px;
  /* height: 0; */
  box-sizing: border-box;
  /* -webkit-transition: all .3s; */
  transition: all 0.3s;
  overflow: hidden;
  /* margin: 0 16px; */
}

.goods-price-intro .block-top {
  margin-top: 12px;
}

.goods-price-intro .block {
  margin-bottom: 12px;
  padding: 0 8px;
  line-height: 16px;
  color: #969799;
}

.goods-price-intro .text {
  display: inline;
  line-height: 20px;
  font-size: 12px;
  color: #333;
}

.goods-price-intro .block {
  margin-bottom: 12px;
  padding: 0 8px;
  line-height: 16px;
  color: #969799;
}

.goods-price-intro .block--small {
  font-size: 12px;
}

.goods-price-intro .block {
  margin-bottom: 12px;
  padding: 0 8px;
  line-height: 16px;
  color: #969799;
}

/* 顶部返回 */
.head .van-nav-bar__content {
  background-color: #ffffff;
}

.head .van-nav-bar__text {
  color: #000000;
  font-weight: 400;
}

/* /deep/.el-dialog {
  border-radius: 5px;
}

/deep/.el-dialog__body {
  padding: 0 !important;
} */

/* 自己的 */
.product_detail_ts_title {
  font-size: 13px;
  color: #666;
  margin-right: 5px;
}

.product_detail_ts_value {
  font-size: 13px;
  color: rgb(76, 175, 80);
}

.icon_name {
  padding: 0 2px;
  font-weight: 400;
  color: #9d9d9d;
  font-size: 12px;
}

/* 商品详情返回 */
.head-wrapper {
  z-index: 99;
  display: flex;
  top: 0;
  left: 0;
  width: 100%;

  align-items: center;
  justify-content: space-between;

  align-items: center;
  position: fixed;
  height: 65px;
  padding: 15px;
}

.head-wrapper .head-menu {
  display: flex;
  align-items: center;
  height: 30px;
  width: 120px;
  background: rgba(0, 0, 0, 0.25);
  border-radius: 15px;
}

.head-wrapper .head-menu .icon-fanhuijiantou {
  border-right: 1px solid #fff;
}

.page-detail .head-wrapper .head-menu .iconfont {
  flex: 1;
  text-align: center;
  color: #fff;
  box-sizing: border-box;
  font-size: 15px;
}

.head-wrapper .head-menu .iconfont {
  flex: 1;
  text-align: center;
  color: #fff;
  box-sizing: border-box;
  font-size: 15px;
}

/* vip升级页面 */

.svipCon {
  height: 42px;
  padding: 0 8px;
  margin: 10px 0px;
  background: #fae3bb;
  font-size: 13px;
  color: #b37400;
  border-radius: 5px;
}

.svipCon uni-image {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.svipCon .svipBtn {
  font-size: 12px;
  color: #b37400;
}

.custom-icon .van-icon {
  font-size: 20px;
  /* 修改图标的大小 */
}

.acea-row {
  display: flex;
  flex-wrap: wrap;
}

.detail_label {
  /* margin: 0 16px; */
  font-size: 12px;
  color: #82848f;
  margin-top: 12px;
}

.acea-row.row-between-wrapper {
  align-items: center;
  justify-content: space-between;
}

/* 规格类 */

.mobile-skuCate {
  margin-top: 15px;
  /* margin-bottom: 15px; */
  display: flex;
  align-items: center;
}

.mobile-SkuCateText {
  /* width: 100px; */
  padding-right: 4px;
  display: inline-block;
}

.mobile-skuCateText {
  height: 14px;
  font-family: PingFangSC-Regular;
  font-size: 14px;
  color: #7f7f7f;
  line-height: 10px;
  /* margin-top: 9px; */
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.mobile-skuItemWrapper {
  display: inline-block;
  vertical-align: top;
  /* width: 620px; */
  margin-left: -4.5px;
}

.mobile-skuItem {
  min-width: 36px;
  max-width: 300px;
  min-height: 36px;
  display: inline-block;
  vertical-align: top;
  /* margin-bottom: 9px; */
  margin-right: 4.5px;
  margin-left: 4.5px;
  padding: 2px;
  border-radius: 6px;
  background-color: rgba(0, 0, 0, 0.06);
  border: 1px solid transparent;
  cursor: pointer;
  text-align: center;
  position: relative;
}

.mobile-skuIcon {
  width: 30px;
  height: 30px;
  border-radius: 3px;
}

.mobile-skuIcon + .skuValueName {
  max-width: 264px;
  padding-left: 4px;
}

.mobile-skuValueName {
  display: inline-block;
  font-size: 14px;
  line-height: 14px;
  color: #333;
  text-overflow: ellipsis;
  padding: 0 8px;
  white-space: nowrap;
  overflow: hidden;
  max-width: 294px;
  line-height: 30px;
  vertical-align: middle;
}

.sku_select {
  background-color: #ffcc00;
  /* 这里是选中状态的背景颜色 */
  color: #ffffff;
}

/* 添加一个自定义类来覆盖默认的标题样式 */
.centered-dialog .el-dialog__title {
  display: flex;
  justify-content: center;
  font-size: 20px;
  font-weight: bold;
}

.address_class::before {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  top: 80px;
  height: 2px;
  background: repeating-linear-gradient(
    -45deg,
    #ff6c6c 0,
    #ff6c6c 20%,
    transparent 0,
    transparent 25%,
    #1989fa 0,
    #1989fa 45%,
    transparent 0,
    transparent 50%
  );
  background-size: 80px;
  content: "";
}

/* 销售渠道样式 */

.channel {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background-color: #fff;
  padding: 10px 12px;
  margin: 0 auto;
  border-bottom: 0.1px solid #e2e5eb;
  box-sizing: border-box;
}

.channel .channel-title {
  font-size: 13px;
  font-weight: 500;
  color: #1e2331;
  margin-left: 0.3125rem;
}

.channel .channel-content {
  display: flex;
  height: auto;
  flex: 1;
  flex-wrap: wrap;
  gap: 0.625rem;
}

.channel .channel-content .channel_item {
  font-size: 13px;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.1875rem;
}

.channel_item_icon {
  width: 14px;
  height: 14px;
  margin-right: 1px;
}

.morder-time {
  font-size: 0.75rem;
  color: #fd4d20;
  font-weight: 700;
}

.mgoods_item {
  position: relative;
  display: flex;
  align-self: flex-start;
  overflow: hidden;
  box-sizing: border-box;
  /* border-radius: 0px 0px 12px 12px; */
  flex-direction: row;
  background-color: rgb(255, 255, 255);
  padding: 12px 12px 12px;
  /* margin: 0px 9px; */
  width: 100%;
  /* max-width: calc(100% - 18px); */
  height: auto;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  margin-top: 8px;
  font-size: 13px;
}

.mgoods_item_content {
  position: relative;
  display: flex;
  flex-shrink: 0;
  flex-grow: 0;
  background-color: rgb(255, 255, 255);
  font-size: 13px;
  margin: 0px;
  width: 208px;
  visibility: visible;
  color: rgb(17, 17, 17);
  max-width: 208px;
  height: 17px;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
  -webkit-box-pack: start;
  -webkit-box-align: center;
}
</style>
