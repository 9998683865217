<template>
  <div class="module-common" :style="[
    !module.base.m ? { 'margin-top': '0' } : {},
    {
      padding:
        '0px ' + module.base.margins + 'px 0px ' + module.base.margins + 'px',
    },
  ]">
    <div>
      <div class="gds-list3">
        <van-tabs :offset-top="50" @click="toPCate($event)" :ellipsis="false">
          <van-tab :title="item.categoryName" v-for="item in catesList" :key="item.id" :name="item.id">
            <div>
              <div style="display: flex; flex: 1; height: calc(100%)">
                <div style="
                    background-color: #ffffff;
                    font-size: 14px;
                    margin-right: 6px;
                  ">
                  <van-sidebar v-model="activeKey" @change="onChange">
                    <van-sidebar-item style="background-color: #ffffff" :title="value.categoryName"
                      v-for="value in item.children" :key="value.id" :name="value.id" @click="toSecondPCate(value)" />
                  </van-sidebar>
                </div>

                <div style="
                    box-sizing: border-box;
                    height: 100%;
                    overflow: auto;
                    flex: 1;
                  ">
                  <van-list v-model="loadingState" :finished="finished" finished-text="没有更多了" @load="loadMore">
                    <div class="main-container">
                      <div>
                        <div class="kind-show goods-list">
                          <!---->
                          <div class="goods3-content">
                            <div class="goods-item" :class="{ overlay: v.inventoryState == '3' }"
                              v-for="(v, k, i) in goodsList" :key="i" :title="v.name" @click="toProductDetails(v)">
                              <van-row>
                                <van-col span="8">
                                  <!-- <van-image :src="v.picUrl" /> -->

                                  <el-image :src="v.picUrl" style="padding: 5px"></el-image>
                                </van-col>
                                <van-col span="16">
                                  <div class="product_centent">
                                    <div class="name">
                                      {{ v.productName }}
                                    </div>
                                    <div class="fun">
                                      <span v-html="$decimalPrice(v.price)">
                                      </span>
                                    </div>
                                  </div>
                                </van-col>
                              </van-row>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </van-list>
                </div>

                <!-- 底部间隔 -->
                <div style="height: 180px"></div>
                <!-- 底部间隔 -->
                <div style="height: 180px"></div>
                <!-- 底部间隔 -->
                <div style="height: 180px"></div>
                <!-- 底部间隔 -->
                <div style="height: 180px"></div>
              </div>
            </div>
          </van-tab>
        </van-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { queryProductList } from "@/api/index";

export default {
  props: ["module"],

  name: "HelloWorld",
  data() {
    return {
      pageHeight: undefined,
      activeKey: undefined,
      showOverlay: true,
      goodsLabelList: [],
      goodsList: [],
      queryParam: {
        pageNum: 1,
        pageSize: 10,
        categoryId: undefined,
        searchKeyword: undefined,
        queryCondition: null,
      },
      goodsName: "",
      goodsStatus: false,
      pageNum: 1,
      pageSize: 10,
      loadingState: false,
      finished: false,
      isFetching: false, // 是否有正在进行中的请求
      catesList: [],
    };
  },
  computed: {
    ...mapGetters({}),
    ...mapState({
      producCategorytList: (state) => state.index.producCategorytList,
      productList: (state) => state.index.productList,
    }),
  },
  created() {
    this.pageHeight = window.innerHeight;

    this.queryParam.categoryId = this.$route.query.categoryId;
    this.queryParam.searchKeyword = this.$route.query.goodsName;

    //获取商品分类
    this.loadCate();
    //获取获取商品列表
    this.loadData();
  },
  methods: {
    onChange(index) {
      console.log(index);
    },

    //获取商品分类
    loadCate() {
      let data = {};
      this.$store.dispatch("index/queryProducCategorytList", data).then(() => {
        this.catesList = this.producCategorytList.list;
      });
    },

    //查询商品列表
    loadData() {
      this.isFetching = true;
      new Promise((resolve, rejust) => {
        queryProductList(this.queryParam)
          .then((res) => {
            //主站
            this.goodsList = this.goodsList.concat(res.result.productList);

            this.total = res.result.total;
            if (res.result.productList.length == 0) {
              this.finished = true;
            }
            this.loadingState = false;
            this.isFetching = false;

            resolve();
          })
          .catch((error) => {
            rejust(error);
          });
      });
    },

    toPCate(k) {
      console.log(k);
      this.loadingState = true;
      this.finished = false;

      this.goodsList = [];
      this.queryParam.pageNum = 1;
      this.queryParam.pageSize = 10;
      this.queryParam.categoryPid = k;
      this.queryParam.categoryId = undefined;
      this.loadData();
    },

    toSecondPCate(k) {
      this.loadingState = true;
      this.finished = false;

      this.goodsList = [];
      this.queryParam.pageNum = 1;
      this.queryParam.pageSize = 10;
      this.queryParam.categoryPid = undefined;
      this.queryParam.categoryId = k.id;
      this.loadData();
    },

    toCate(v) {
      this.loadingState = true;
      this.finished = false;

      this.goodsList = [];
      this.queryParam.pageNum = 1;
      this.queryParam.pageSize = 10;
      this.queryParam.categoryId = v.id;
      this.queryParam.categoryPid = undefined;
      this.loadData();
    },

    /**
     * 加载更多
     */
    loadMore() {
      if (this.isFetching) {
        // 如果有正在进行中的请求，则不继续发起新的请求
        return;
      }
      this.queryParam.pageNum = this.queryParam.pageNum + 1;
      this.loadingState = true;
      this.loadData();
    },

    //跳转商品详情界面
    toProductDetails(v) {
      // this.$router.push({
      //   path: "product_detail",
      //   query: { product_id: v.id },
      // });
      this.$router.push(`/product_detail/${v.id}`);
    },
  },
};
</script>
<style>
.goods3-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 0.625rem;
}

.goods3-content .goods-item {
  width: 100%;
  /* height: 6.25rem; */
  background: #ffffff;
  box-shadow: 0 0.125rem 0.1875rem #f5f7fa;
  border-radius: 0.3125rem;
  /* display: flex; */
  /* align-items: center; */
  justify-content: flex-start;
  /* gap: 0.625rem; */
  padding: 10px;
}

.goods3-content .goods-item .goods-img {
  width: 5rem;
  /* height: 5.625rem; */
  border-radius: 5px;
  overflow: hidden;
}

.goods3-content .goods-item .product_centent {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  height: 100%;
  gap: 10px;
  margin-left: 10px;
}

.goods3-content .goods-item .product_centent .name {
  color: #333333;
  font-weight: 400;
  font-size: 14px;
  /* white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 14.375rem; */
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  height: 40px;
}

.goods3-content .goods-item .product_centent .fun {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 10px;
}

.goods3-content .goods-item .product_centent .fun .money {
  font-weight: 500;
  color: #f84d43;
  font-size: 1.125rem;
}

.goods3-content .goods-item .product_centent .fun .money .default-money {
  font-weight: 400;
  color: #d2d5dd;
  font-size: 0.6875rem;
  text-decoration: line-through;
  margin-left: 0.53125rem;
}

.goods3-content .goods-item .product_centent .fun .buy-btn {
  width: 4.0625rem;
  height: 1.5625rem;
  background: linear-gradient(90deg, #fe8a56 0%, #ff504b 100%);
  border-radius: 0.78125rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #fff;
}

/* 组件样式 */
.m-menu-class .el-submenu__title {
  padding-left: 10px !important;
}

.m-menu-class .el-submenu__icon-arrow {
  right: 10px;
}

.m-menu-class .el-submenu .el-menu-item {
  min-width: 0px;
}

.gds-list3 .van-tab--active {
  border-radius: 50px;
  background-color: #eb6253;
  font-size: 14px;
  color: #fff;
  margin: 8px 0px;
}

.gds-list3 .van-tabs__line {
  display: none;
}

.gds-list3 .van-tabs__nav {
  /* background-color: transparent; */
}

/* .gds-list3 .van-tabs__content {
  margin-top: 10px;
} */

.gds-list3 .van-tabs__nav--line.van-tabs__nav--complete {
  padding-right: 0px;
  padding-left: 0px;
}

.gds-list3 .van-grid-item__text {
  font-size: 14px;
}
</style>
