<template>
  <div>
    <!-- <Header /> -->

    <div class="container">
      <div class="order-content">
        <div class="goods-detail" style="border: 1px solid #cdbdbd">
          <!-- 收货地址 -->
          <div v-if="orderDetails.productType === '2'">
            <div class="title">
              请选择收货地址
              <el-button type="primary" @click="insterAddress" style="margin-left: 5px" size="small">
                立即添加
              </el-button>
            </div>
            <div class="by-info-content" v-if="this.addressList.length > 0">
              <div class="order-address">
                <div class="address-list">
                  <div class="addr-item-wrapper TwoRow" :class="{
                    'addr-default': selectedIndex == index,
                    'addr-selected': selectedIndex == index,
                  }" v-for="(item, index) in addressList" :key="index" @click="selectAddr(item, index)">
                    <div class="inner-infos">
                      <div class="addr-hd">
                        <span>{{ item.regionProvinceName }}</span>
                        <span>{{ item.regionCityName }}</span>
                        <span>{{ item.regionCountryName }}</span>
                        <span>{{ item.address }}</span>
                      </div>
                      <div class="addr-bd">
                        <div style="margin-top: 5px">
                          {{ item.name }} {{ item.tel }}
                        </div>
                      </div>
                    </div>
                    <div class="curMarker"></div>
                  </div>
                </div>
              </div>
            </div>

            <div v-else>
              <el-empty description="暂无收货地址" :image-size="100">
              </el-empty>
            </div>
          </div>

          <!-- 确认订单信息 -->
          <div>
            <div class="title">商品信息</div>
            <div class="qr-order-content">
              <span class="goods-type">
                {{ orderDetails.productTypeText }}
              </span>
              <div>
                <el-image :src="orderDetails.productImg"></el-image>
              </div>

              <div>
                <el-row :gutter="20">
                  <el-col :span="24" style="padding-top: 15px; padding-bottom: 15px">
                    <span style="color: #999">商品名称：</span>

                    <span style="font-size: 16px; font-weight: 700">
                      {{ orderDetails.productName }}
                    </span>
                  </el-col>

                  <el-col :span="24" style="padding-top: 15px; padding-bottom: 15px">
                    <span style="color: #999">商品单价：</span>
                    <span style="color: red; font-size: 16px; font-weight: 700">
                      {{ orderDetails.price }}
                    </span>
                  </el-col>

                  <el-col :span="24" style="padding-top: 15px; padding-bottom: 15px">
                    <span style="color: #999">下单数量：</span>

                    <span>
                      <!-- 区间 -->
                      <template v-if="orderDetails.dealQuantityType === 1">
                        <el-input-number v-model="orderForm.quantity" :min="orderDetails.dealQuantityMin" size="medium"
                          :max="orderDetails.dealQuantityMax" placeholder="请选择下单数量"></el-input-number>
                      </template>

                      <!-- 自定义 -->
                      <template v-if="orderDetails.dealQuantityType === 2">
                        <el-select v-model="orderForm.quantity" placeholder="请选择" size="medium">
                          <el-option v-for="item in dealQuantityArray(
                            orderDetails.dealQuantityArray
                          )" :key="item" :label="item" :value="item">
                          </el-option>
                        </el-select>
                      </template>
                    </span>
                  </el-col>
                </el-row>
              </div>
            </div>
          </div>

          <div class="order_spacing">
            <div v-if="this.orderDetails.orderParams.length > 0">
              <div class="add-info-title">请输入充值信息</div>
              <div style="margin: 0px 20px">
                <div>
                  <el-form label-position="top" label-width="120px">
                    <div>
                      <orderParam :orderParams="orderDetails.orderParams" v-model="orderDetails.orderParams">
                      </orderParam>
                    </div>
                  </el-form>
                </div>
              </div>
            </div>
          </div>

          <div class="order_spacing">
            <div class="by-info-title">订单备注</div>
            <div style="padding: 0px 15px">
              <el-form style="margin-top: 10px">
                <el-form-item>
                  <el-input type="textarea" :autosize="{ minRows: 2, maxRows: 4 }" :rows="2" placeholder="请输入订单备注(非必填)"
                    v-model="orderForm.deliveryRemark">
                  </el-input>
                </el-form-item>
              </el-form>
            </div>
          </div>

          <div class="payType">
            <div class="title">支付方式</div>
            <div class="type pay-types" style="padding: 0px 15px">
              <el-radio-group v-model="payConfigDetails">
                <el-radio border label="balance">
                  余额支付（￥{{ balanceTotal }}）
                </el-radio>
                <el-radio border v-for="(value, key, index) in payList" :key="index" :label="value"
                  @change="toPopPayTis(value)">
                  {{ value.payName }}
                </el-radio>
              </el-radio-group>
            </div>
          </div>

          <div class="order_spacing">
            <div style="
                display: flex;
                justify-content: center;
                padding-top: 30px;
                padding-bottom: 20px;
              ">
              <el-button type="danger" round @click="handleGoBuy()" style="width: 400px; font-size: 18px">确认购买( ￥{{
                $calculateProductPrice(
                  orderForm.quantity,
                  orderDetails.price,
                  orderDetails.orderParams
                )
              }})</el-button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Payment ref="paymentComponent" :orderId="this.orderId" :payConfig="this.payConfigDetails"
      :orderParams="this.orderDetails.orderParams" :deliveryRemark="this.orderDetails.deliveryRemark"></Payment>

    <!-- 添加收货地址 -->
    <el-dialog title="添加收货地址" :visible.sync="dialogVisible" :modal-append-to-body="false">
      <el-card shadow="never">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-position="top">
          <el-form-item label="姓名" prop="deliveryPerson">
            <el-input v-model="ruleForm.deliveryPerson" placeholder="请输入姓名"></el-input>
          </el-form-item>

          <el-form-item label="联系电话" prop="deliveryPhone">
            <el-input v-model="ruleForm.deliveryPhone" placeholder="请输入姓名"></el-input>
          </el-form-item>

          <el-form-item label="所在地区" prop="productId">
            <el-cascader v-model="fieldValue" :props="{
              value: 'id',
              label: 'regionName',
              children: 'children',
            }" :options="treeList" @change="handleChange">
              <template slot-scope="{ node, data }">
                <span>{{ data.regionName }}</span>
                <span v-if="!node.isLeaf"> ({{ data.children.length }}) </span>
              </template>
            </el-cascader>
          </el-form-item>

          <el-form-item label="详细地址" prop="deliveryAddress">
            <el-input v-model="ruleForm.deliveryAddress" placeholder="请输入详细地址"></el-input>
          </el-form-item>

          <el-form-item>
            <el-button type="primary" @click="submitForm('ruleForm')">
              确认
            </el-button>
            <el-button @click="dialogVisible = false">取消</el-button>
          </el-form-item>
        </el-form>
      </el-card>
    </el-dialog>

    <!-- 支付提示 -->
    <el-dialog title="支付提示" :visible.sync="dialogPayTisVisible" :modal-append-to-body="false" center>
      <div style="padding: 4px">
        <span v-html="dialogPayTis"></span>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogPayTisVisible = false">
          我已知晓
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { mapGetters, mapState } from "vuex";
import Payment from "@/components/payment/Payment";
import orderParam from "@/components/orderParam.vue";
import { getAddressList } from "@/api/useraddress";
import { queryProductDetail } from "@/api/index";

import { Toast } from "vant";

import { regionTree, address } from "@/api/useraddress";
export default {
  data() {
    return {
      dialogPayTisVisible: false,
      dialogPayTis: undefined,
      rules: {
        deliveryPerson: [
          { required: true, message: "请输入姓名", trigger: "blur" },
        ],
        deliveryPhone: [
          { required: true, message: "请输入联系电话", trigger: "blur" },
        ],
        deliveryAddress: [
          { required: true, message: "请输入详细地址", trigger: "blur" },
        ],
      },
      ruleForm: {
        deliveryPerson: undefined,
        deliveryPhone: undefined,
        deliveryAddress: undefined,

        //省
        regionProvince: undefined,
        regionProvinceName: undefined,

        regionCity: undefined,
        regionCityName: undefined,

        regionCountry: undefined,
        regionCountryName: undefined,
      },
      selectedIndex: undefined,
      addressList: [],
      payConfigDetails: "balance",
      dialogVisible: false,
      spaecsPirce: 0,
      payList: {},
      rechargeAccount: "",
      orderDetails: [],
      queryParam: {
        specsId: undefined,
        rechargeAccount: undefined,
      },
      orderForm: {
        addressId: undefined,
        //收件人姓名
        // deliveryPerson: null,
        //县编码
        // deliveryCountry: "",
        //省编码
        // deliveryProvince: null,
        //市编码
        // deliveryCity: null,
        //省名称
        // deliveryProvinceName: "",
        //城市名称
        // deliveryCityName: "",
        //地址
        // deliveryAddress: "",
        //手机号
        // deliveryPhone: "",
        //运费模版
        // deliveryConfigId: 1,
        // deliveryConfig: "",
        //成交平台 0:web 1:ios 2:android 3:wx
        // dealPlatform: 1,
        //备注
        deliveryRemark: "",
        //邮编
        // deliveryPostcode: "",
        //充值账号
        //充值数量
        quantity: undefined,
        //商品id
        productId: null,
        orderParams: [],
        stockId: undefined,
        treeList: [],
      },
    };
  },
  components: {
    Payment,
    orderParam,
  },
  computed: {
    ...mapGetters({}),
    ...mapState({
      orderDetail: (state) => state.order.orderDetail,
      payConfig: (state) => state.index.payConfig,
      orderState: (state) => state.order.orderState,
      balanceTotal: (state) => state.balance.balanceTotal,
      orderId: (state) => state.order.orderId,
    }),
  },
  created() {
    //查询我的余额
    this.$store.dispatch("balance/detail");

    //初始化支付通道
    this.initPayConfig();
    //获取获取商品详情
    this.getProductDetail();

    //查询收货地址
    this.queryAddressList();

    this.getRegionTree();
  },
  methods: {
    dealQuantityArray(value) {
      return value.split(",");
    },

    //初始化支付通道
    initPayConfig() {
      let data = {};
      this.$store.dispatch("index/queryPayConfig", data).then(() => {
        this.payList = this.payConfig.payList;
        // this.$forceUpdate();
      });
    },

    /**
     * 弹框支付提示
     */
    toPopPayTis(value) {
      console.log(value);
      console.log("====================================================");
      let tip = value.payTip;
      if (tip) {
        this.dialogPayTisVisible = true;
        this.dialogPayTis = tip;
      }
    },

    //获取获取商品详情
    async getProductDetail() {
      let data = { product_id: this.$route.query.productId };
      const response = await queryProductDetail(data);

      this.orderDetails = response.result;

      //赋值默认参数
      if (this.orderDetails.dealQuantityType === 1) {
        this.orderForm.quantity = this.orderDetails.dealQuantityMin;
      }

      if (this.orderDetails.dealQuantityType === 2) {
        let data = this.orderDetails.dealQuantityArray.split(",");

        this.orderForm.quantity = data[0];
      }
    },

    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          new Promise((resolve, rejust) => {
            address(this.ruleForm)
              .then((res) => {
                console.log(res);
                if (res.status == 0) {
                  Toast.success({
                    message: "添加成功",
                  });
                  this.dialogVisible = false;
                  this.queryAddressList();
                }
                resolve();
              })
              .catch((error) => {
                rejust(error);
              });
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },

    //选择地区
    handleChange(selectedOptions) {
      //省
      this.ruleForm.regionProvince = selectedOptions[0];

      this.ruleForm.regionCity = selectedOptions[1];

      this.ruleForm.regionCountry = selectedOptions[2];
    },

    getRegionTree() {
      new Promise((resolve, rejust) => {
        regionTree({})
          .then((res) => {
            this.treeList = res.result;
            resolve();
          })
          .catch((error) => {
            rejust(error);
          });
      });
    },

    /**
     * 新增收货地址
     */
    insterAddress() {
      this.dialogVisible = true;

      this.fieldValue = undefined;

      this.ruleForm.deliveryPerson = undefined;
      this.ruleForm.deliveryPhone = undefined;
      this.ruleForm.deliveryAddress = undefined;

      //省
      this.ruleForm.regionProvince = undefined;
      this.ruleForm.regionProvinceName = undefined;

      this.ruleForm.regionCity = undefined;
      this.ruleForm.regionCityName = undefined;

      this.ruleForm.regionCountry = undefined;
      this.ruleForm.regionCountryName = undefined;
    },

    //查询收货地址
    queryAddressList() {
      new Promise((resolve, rejust) => {
        getAddressList(this.queryParam)
          .then((res) => {
            this.addressList = res.result;
            resolve();
          })
          .catch((error) => {
            rejust(error);
          });
      });
    },

    /**
     * 选择收货地址
     */
    selectAddr(item, index) {
      this.selectedIndex = index;
      this.orderForm.addressId = item.id;
    },

    /**
     * 支付确认关闭事件
     */
    handleClose(done) {
      this.$confirm("确认取消支付？")
        .then(() => {
          done();
        })
        .catch(() => { });
    },

    //支付方法
    handleGoBuy() {
      this.orderForm.productId = this.orderDetails.id;
      this.orderForm.orderParams = this.orderDetails.orderParams;
      this.orderForm.stockId = this.orderDetails.productStock.id;
      this.$store
        .dispatch("order/create", this.orderForm)
        .then(() => {
          this.$refs.paymentComponent.toPayment();

          // this.$router.push({
          //   path: "/confirmPayment",
          //   query: { orderId: this.orderId },
          // });
        })
        .catch(() => { });
    },

    //跳转到首页
    toIndex() {
      this.$router.push("/index");
    },
    /**
     * 返回上页
     */
    onClickLeft() {
      this.$router.back();
    },
  },
  // 离开页面销毁轮询器
  destroyed() {
    clearInterval(this.T);
  },
};
</script>

<style scoped>
.section .zf_order {
  margin: 18px auto 0;
  /* padding-top: 38px; */
  background: #fff;
  -webkit-box-shadow: 0 3px 3px 0 hsla(0, 0%, 92.5%, 0.44);
  box-shadow: 0 3px 3px 0 hsla(0, 0%, 92.5%, 0.44);
  border-radius: 12px;
  text-align: center;
  padding-bottom: 38px;
}

.section .con {
  margin: 3px auto 0;
  padding-top: 10px;
  background: #fff;
  -webkit-box-shadow: 0 3px 3px 0 hsla(0, 0%, 92.5%, 0.44);
  box-shadow: 0 3px 3px 0 hsla(0, 0%, 92.5%, 0.44);
  border-radius: 12px;
  text-align: center;
  padding-bottom: 38px;
}

.section .zf_order .col-12 .order {
  width: 340px;
  margin: 15px auto 21px;
  background: #fbfbfb;
  border-radius: 6px;
  line-height: 42px;
  text-align: center;
}

.section .zf_order .col-12 .order span:first-child {
  color: #999;
  font-size: 15px;
  margin-left: 14px;
}

.section .zf_order .col-12 .order span:nth-child(2) {
  color: #3259ff;
  font-size: 13px;
  float: right;
  margin-right: 21px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
}

.goods_name {
  font-weight: 500;
  font-size: 12px;
  color: #999;
  border-bottom: 1px solid #f5f5f5;
  padding-bottom: 20px;
}

.goods_name span:nth-child(2) {
  margin-left: 14px;
}

.yuanmoney {
  width: 340px;
  margin: 15px auto 21px;
  background: #fbfbfb;
  border-radius: 6px;
  line-height: 42px;
  text-align: left;
}

.yuanmoney span:nth-child(2) {
  color: #3259ff;
  font-size: 13px;
  float: right;
  margin-right: 21px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
}

.pay_type {
  width: 100%;
  text-align: center;
  margin-top: 10px;
}

.pay_type img {
  display: inline-block;
  vertical-align: middle;
  width: 23px;
}

.pay_type span {
  font-weight: 700;
  font-size: 14px;
  color: #545454;
  margin-left: 3px;
  display: inline-block;
  vertical-align: middle;
}

.code_cs,
.code {
  height: 208px;
  background: #fbfbfb;
  position: relative;
  width: 208px;
  margin-top: 10px;
  margin-left: -104px;
  left: 50%;
}

.code_cs {
  height: 208px;
  background: #ffffff;
}

.code_cs img {
  position: absolute;
  width: 49px;
  left: 50%;
  margin-left: -25px;
  top: 50%;
  margin-top: -25px;
  padding: 10px;
}

.code {
  border: 5px solid #d8d1d1;
  border-radius: 5px;
  position: relative;
  width: 208px;
  margin-top: 10px;
  margin-left: -104px;
  left: 50%;
  display: block;
  padding: 3px;
}

.price {
  color: #386cfa;
  width: 100%;
  text-align: center;
  top: 65px;
}

.price span:first-child {
  font-size: 28px;
}

.price span {
  font-weight: 700;
}

.price span:nth-child(2) {
  font-size: 17px;
}

.price span {
  font-weight: 700;
}

.shanxinzha {
  margin-top: 32px;
  width: 100%;
  text-align: center;
}

.shanxinzha img {
  display: inline-block;
  vertical-align: middle;
  width: 26px;
  /* -webkit-animation: xuanzhuan 0.8s linear infinite; */
}

.shanxinzha span {
  color: #999;
  font-size: 14px;
  font-weight: 400;
  margin-left: 5px;
}

.shanxinzha span p {
  display: inline-block;
  color: #386cfa;
}

.section--last {
  margin-bottom: 20px;
}

/* 新支付页面 */
.qr-order-content {
  width: 100%;
  background-color: #f5f5f5;
  overflow: hidden;
  padding-top: 23px;
}

.qr-order-content .bt {
  width: 100%;
  height: 64px;
  background-color: #eaf2ff;
  color: #0a72ff;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  line-height: 64px;
}

.qr-order-content .box1 {
  width: 100%;
  background-color: #fff;
  overflow: hidden;
  /* margin-bottom: 80px;
  padding-bottom: 80px; */
  margin: 20px 0 40px;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 5px 10px rgb(0 0 0/5%);
  display: flex;
}

.qr-order-content .box1-1 {
  /* width: 644px; */
  margin: 0 auto;
  /* margin-top: 42px; */
  overflow: hidden;
  background-color: #f5f5f5;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.qr-order-content .box1-1 ul li {
  line-height: 35px;
  font-size: 16px;
  color: #333333;
}

.qr-order-content .box1-2 {
  /* width: 644px; */
  margin: 0 auto;
  margin-top: 42px;
  overflow: hidden;
}

.qr-order-content .box1-2 ul {
  width: 100%;
}

.qr-order-content .box1-2 ul li {
  float: left;
  width: 131px;
  height: 42px;
  margin-right: 6px;
  border: 1px solid #d6d6d6;
  border-radius: 3px;
  font-size: 14px;
  color: #333333;
  line-height: 42px;
  margin-top: 30px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.box1-3 {
  /* width: 644px; */
  overflow: hidden;
  margin: 0 auto;
  /* margin-top: 50px; */
}

.qr-order-content .box1-1 ul li {
  margin-bottom: 10px;
  list-style: none;
}

.qr-order-content .box1-1 ul li span.label {
  display: inline-block;
  width: 90px;
  font-weight: bold;
  color: #333;
  font-size: 16px;
}

.qr-order-content .box1-1 ul li span.value {
  color: #5e72e4;
  font-size: 16px;
  font-weight: bold;
}

.order-content {
  width: 100%;
  margin-top: 20px;
  background: #f9f9fa;
}

.order-content .goods-detail {
  padding: 5px;
  width: 100%;
  border-radius: 20px;
  background-color: #fff;
}

.order-content .goods-detail .add-info-title,
.order-content .goods-detail .by-info-title,
.order-content .goods-detail .title {
  color: #303133;
  padding: 16px;
  margin: 0 10px;
  font-weight: 700;
  color: #333;
  font-size: 18px;
  position: relative;
  margin-left: 20px;
}

.order-content .goods-detail .add-info-title:before,
.order-content .goods-detail .by-info-title:before,
.order-content .goods-detail .title:before {
  content: "";
  height: 20px;
  width: 5px;
  background: #2b6fff;
  display: block;
  position: absolute;
  top: 50%;
  left: 1px;
  border-radius: 0.18rem;
  transform: translateY(-50%);
}

.order-content .goods-detail .qr-order-content {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  height: 180px;
  margin: 0 auto;
  width: 98%;
  padding: 0 0.56rem;
  background: #f9f9fa;
  border-radius: 0.18rem;
}

.order-content .goods-detail .qr-order-content span.goods-type {
  position: absolute;
  top: 0;
  right: 0;
  width: 160px;
  height: 40px;
  background: #1890ff;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  border-radius: 0 10px 0 10px;
}

.order-content .goods-detail .qr-order-content .el-image {
  width: 150px;
  height: 150px;
  border-radius: 10px;
  margin-right: 10px;
}

.order-content .goods-detail .qr-order-content .row-content {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.order-content .goods-detail .qr-order-content .conter {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 0.9rem;
  border-radius: 0.1rem;
  border: 1px solid #dcdfe6;
  overflow: hidden;
}

.order-content .goods-detail .qr-order-content .conter:hover {
  border-color: #1890ff;
}

.order-content .goods-detail .qr-order-content .conter .conter-input {
  display: block;
  height: 100%;
  width: 2.5rem;
  font-size: var(--sizeM, 0.25rem);
  outline: none;
  border: none;
  text-align: center;
  padding: 0 !important;
  margin: 0 !important;
}

.order-content .goods-detail .qr-order-content .conter .icon {
  width: 0.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ddd;
  height: 100%;
  cursor: pointer;
}

.order-content .goods-detail .qr-order-content .conter .icon:hover {
  color: #1890ff;
}

.order-content .goods-detail .add-info-content {
  display: flex;
}

.order-content .goods-detail .add-info-content .el-form {
  padding-top: 4px;
}

.order-content .goods-detail .by-info-content {
  width: 98%;
  padding: 0.36rem;
  padding-left: 0;
  margin: 0 auto;
  /* background-color: #fbfbfb; */
  display: flex;
  justify-content: space-between;
  border-top: 0.018rem solid #ddd;
}

.order-content .goods-detail .by-info-content .pay-computed {
  width: 20%;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #666;
}

.order-content .goods-detail .by-info-content .pay-computed .el-row {
  margin: 15px auto;
}

.order-content .goods-detail .by-info-content .pay-computed .el-row .sub-btn {
  width: 4.44rem;
  height: 0.89rem;
  background: #1890ff;
  border-radius: 0.1rem;
  font-size: var(--sizeXL, 0.35rem);
}

.order-content .goods-detail .choose-pay {
  display: flex;
  align-items: center;
  padding: 22px 15px;
  gap: 16px;
}

.order-content .goods-detail .choose-pay p {
  font-size: 14px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #333;
}

.order-content .goods-detail .choose-pay .choose-pay-item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 14px;
}

.order-content .goods-detail .choose-pay .choose-pay-item .pay-item {
  display: flex;
  align-items: center;
  border-radius: 10px;
  border: 1px solid #e6e6e6;
  width: 160px;
  height: 50px;
  justify-content: center;
  gap: 10px;
  cursor: pointer;
}

.order-content .goods-detail .choose-pay .choose-pay-item .pay-item .svg {
  width: 26px;
  height: 26px;
}

.order-content .goods-detail .choose-pay .choose-pay-item .pay-item p {
  font-size: 16px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #333;
}

.order-content .goods-detail .choose-pay .choose-pay-item .active {
  border-color: #1890ff;
}

.order-content .goods-detail .choose-pay .choose-pay-item label {
  white-space: nowrap;
}

.order-content .goods-detail .choose-pay .choose-pay-item .pass-box {
  width: 400px;
}

/* 收件地址css */

.order-address .content-container .mobile {
  color: grey;
  font-weight: 700;
}

.order-address .content-container .selected-description {
  display: inline;
  color: #f40;
  line-height: 26px;
  margin-left: -80px;
  margin-right: 18px;
}

.order-address .content-container .selected-description .marker {
  font-family: tp-buy-font;
  font-size: 22px;
  font-style: normal;
  color: #f40;
  line-height: 26px;
  -webkit-font-smoothing: antialiased;
  -webkit-text-stroke-width: 0.2px;
  -moz-osx-font-smoothing: grayscale;
}

.order-address .content-container .selected-description .marker-tip {
  font-weight: 700;
}

.order-address .content-container .need-update {
  display: inline;
  color: #f40;
  font-weight: 700;
  position: relative;
}

.order-address .content-container .need-update .a4-tip-span {
  display: inline-block;
  position: absolute;
  width: 0;
  height: 0;
  top: -12px;
  left: 15px;
  border: 7px solid transparent;
  border-top-color: #fdb08e;
  z-index: 102;
}

.order-address .content-container .need-update .a4-tip-span .a4-tip-icon {
  position: absolute;
  width: 0;
  height: 0;
  top: -8px;
  left: -6px;
  border: 6px solid transparent;
  border-top-color: #fff0e8;
}

.order-address .content-container.selected {
  margin-right: 66px;
}

.order-address .address-contents.checked .next-radio-label {
  font-size: 14px;
  font-weight: 700;
}

.order-address .failure-icon {
  color: #f40;
}

.order-address .failure-icon,
.order-address .success-icon {
  font-family: tp-buy-font;
  font-style: normal;
  font-size: 18px;
  height: 18px;
  line-height: 18px;
  background-image: none;
  padding-left: 6px;
}

.order-address .success-icon {
  color: #4dbf00;
}

.order-address .addr-item-wrapper.OneRow {
  position: relative;
  padding-left: 92px;
  border: 1px solid #fff;
  line-height: 30px;
  margin: 5px 0;
}

.order-address .addr-item-wrapper.OneRow .modify-operation {
  position: absolute;
  right: 10px;
  bottom: 0;
  margin-left: 10px;
}

.order-address .addr-item-wrapper.OneRow .default-tip,
.order-address .addr-item-wrapper.OneRow .set-default {
  display: none;
}

.order-address .addr-active.addr-item-wrapper.OneRow {
  background-color: #fff0e8;
}

.order-address .addr-selected.addr-item-wrapper.OneRow {
  border-color: #f40;
  background-color: #fff0e8;
  box-shadow: 5px 5px 0 #f3f3f3;
}

.order-address .addr-selected.addr-item-wrapper.OneRow .mask {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.2;
  filter: progid:DXImageTransform.Microsoft.Alpha(opacity=20);
  background-color: #000;
  z-index: 100;
  width: 100%;
  height: 100%;
}

.order-address .addr-default.addr-item-wrapper.OneRow .default-tip {
  display: inline;
}

.order-address .addr-not-default.addr-active.addr-item-wrapper.OneRow .set-default {
  display: inline;
  margin-left: 4px;
  vertical-align: middle;
}

.order-address .addr-item-wrapper.TwoRow {
  display: inline-block;
  vertical-align: top;
  position: relative;
  width: 237px;
  margin: 0 14px 14px 0;
  color: #666;
  cursor: pointer;
}

.order-address .addr-item-wrapper.TwoRow .inner-infos {
  height: 120px;
  z-index: 2;
  position: relative;
  padding: 11px 15px;
  background: url(//img.alicdn.com/tps/i2/T1VPiBXvpeXXbjLKQ7-237-106.png) no-repeat;
  overflow: hidden;
}

.order-address .addr-item-wrapper.TwoRow .curMarker {
  z-index: 3;
  position: absolute;
  background: url(//img.alicdn.com/tfs/TB1zLJRRpXXXXcrXXXXXXXXXXXX-30-600.png) no-repeat;
  background-position: 0 -272px;
  width: 28px;
  height: 28px;
  top: 77px;
  right: 1px;
  visibility: hidden;
}

.order-address .addr-item-wrapper.TwoRow .default-tip,
.order-address .addr-item-wrapper.TwoRow .set-default {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  padding: 0 2px;
  text-decoration: none;
  filter: alpha(opacity=70);
  opacity: 0.7;
  z-index: 3;
}

.order-address .addr-item-wrapper.TwoRow .default-tip {
  background: #ccc;
  color: #fff;
}

.order-address .addr-item-wrapper.TwoRow .set-default {
  color: #666;
  background: #fff;
}

.order-address .addr-item-wrapper.TwoRow .set-default:hover {
  color: #c97;
}

.order-address .addr-item-wrapper.TwoRow .failure,
.order-address .addr-item-wrapper.TwoRow .success {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0 2px;
  opacity: 0.7;
  z-index: 4;
  background-color: #fff5cc;
}

.order-address .addr-active.addr-item-wrapper.TwoRow .inner-infos,
.order-address .addr-selected.addr-item-wrapper.TwoRow .inner-infos {
  background-image: url(//img.alicdn.com/tfs/TB1OVRCRpXXXXaMXFXXXXXXXXXX-237-106.png);
}

.order-address .addr-selected.addr-item-wrapper.TwoRow .inner-infos .addr-bd {
  height: 36px;
}

.order-address .addr-selected.addr-item-wrapper.TwoRow .curMarker {
  visibility: visible;
}

.order-address .addr-default.addr-item-wrapper.TwoRow .default-tip,
.order-address .addr-selected.addr-not-default.addr-active.addr-item-wrapper.TwoRow .set-default {
  display: block;
}

.order-address .operations {
  display: block;
  position: relative;
}

.order-address .operations .manage-link {
  position: absolute;
  right: 0;
  cursor: pointer;
}

.order-address .operations .operation.OneRow {
  margin-left: 118px;
}

.suggest-address-tips .info {
  color: #ff0036;
  padding: 3px 0 8px;
}

.suggest-address-tips .suggest-yes-btn {
  border: 0;
  margin-right: 8px;
}

.suggest-address-tips .lamp-icon {
  font-family: iconfont !important;
  font-size: 14px;
  font-style: normal;
  color: #f8a564;
  position: absolute;
  left: 4px;
  line-height: 26px;
  -webkit-font-smoothing: antialiased;
  -webkit-text-stroke-width: 0.2px;
  -moz-osx-font-smoothing: grayscale;
}

.suggest-address-tips .suggest-no-btn,
.suggest-address-tips .suggest-no-btn:hover {
  color: #08459e;
  text-decoration: none;
}

.suggest-address-tips .street {
  font-weight: 700;
  font-size: 13px;
}

.enforce-address-tips.OneRow,
.suggest-address-tips.OneRow {
  z-index: 101;
  border: 1px solid #fdb08e;
  display: inline-block;
  padding: 0 8px;
  margin-top: 5px;
  line-height: 25px;
  background-color: #fff0e8;
  color: #f40;
  position: relative;
  top: 0;
  left: 40px;
}

.enforce-address-tips.OneRow .a4-warn,
.suggest-address-tips.OneRow .a4-warn {
  display: inline;
  font-family: tp-buy-font;
  font-weight: 700;
  font-size: 16px;
  font-style: normal;
  color: #f40;
  line-height: 25px;
}

.enforce-address-tips.OneRow .info,
.enforce-address-tips.OneRow .operations,
.suggest-address-tips.OneRow .info,
.suggest-address-tips.OneRow .operations {
  display: inline;
}

.enforce-address-tips.OneRow .btn,
.suggest-address-tips.OneRow .btn {
  border: 0;
  background: 0;
  padding-bottom: 4px;
  color: #36c;
}

.suggest-address-tips.TwoRow {
  top: 10px;
  background: #fff4ee;
  border: 1px solid #ffe1d0;
  padding-bottom: 8px;
  padding-left: 19px;
  position: relative;
}

.suggest-address-tips.TwoRow .msg-arrow {
  background-image: url(//img.alicdn.com/tps/i1/TB11ueVHXXXXXaqXpXXXsE_.VXX-14-10.png);
  position: absolute;
  text-decoration: none;
  background-repeat: no-repeat;
  background-size: 100%;
  left: 0.5em;
  top: -4px;
  display: block;
  width: 7px;
  height: 5px;
  line-height: 5px;
}

.enforce-address-tips.TwoRow {
  background: transparent;
  filter: e("progid:DXImageTransform.Microsoft.gradient(startColorstr=#ca000000,endColorstr=#ce000000)"
    );
  background: rgba(0, 0, 0, 0.6);
  position: absolute;
  border-radius: 3px;
  padding: 15px 10px;
  color: #fff;
  left: 5px;
  top: 5px;
  z-index: 2;
  width: 227px;
  height: 96px;
}

.enforce-address-tips.TwoRow .btn {
  margin-top: 12px;
  border: 0;
  color: #fff;
  background-color: #ff0036;
}

.order-address {
  /* margin-bottom: 30px; */
  margin-top: 10px;
}

.order-address .address-tips-top {
  margin-top: -10px;
  margin-bottom: 5px;
}

/* .order-address .address-list {
  width: 1004px;
} */

.order-address .address-tips.TwoRow {
  margin-bottom: 10px;
}

.order-address .address-tips.OneRow {
  margin-bottom: 10px;
  padding-left: 92px;
}

.order-address.TwoRow a {
  color: #c97;
}

.order-address.TwoRow a:hover {
  color: #ff0036;
}

.style-tmall .order-link {
  color: #c97;
}

.style-tmall .order-link:hover {
  color: #ff0036;
}

.service-address {
  margin: 20px 0;
  color: #666;
}

.service-address .choice-label,
.service-address .choice-radio {
  margin-right: 35px;
}

.service-address .notice {
  margin-top: 10px;
}

.service-address.taobao-style {
  padding-left: 92px;
}

.service-address.taobao-style .notice {
  display: none;
}

.service-address .address-list {
  display: inline-block;
  padding: 13px 19px;
  margin-top: 10px;
  background: #f2f2f2;
  position: relative;
  min-width: 400px;
}

.service-address .address-list .address-item {
  display: block;
  margin-bottom: 6px;
}

.service-address .address-list .operation {
  float: right;
}

.service-address .address-list .operations {
  margin-top: 10px;
}

.service-address .address-list .operations .confirm {
  margin-right: 10px;
}

.service-address .address-list .service-address-tip {
  margin-left: 15px;
}

.service-address .address-list:before {
  position: absolute;
  content: "";
  background: url(//img.alicdn.com/tps/i4/TB1v6dnGXXXXXX0XFXXaqiDFpXX-18-10.png) no-repeat;
  width: 18px;
  height: 10px;
  left: 271px;
  top: -10px;
}

.service-address.taobao-style .address-list {
  width: 100%;
}

.service-address.taobao-style .operation {
  float: none;
}

.service-address .address-result {
  margin-top: 14px;
}

.service-address .address-result .change-operation {
  margin-left: 10px;
}

.service-address.taobao-style .address-result {
  color: #3c3c3c;
  padding: 5px 20px 5px 5px;
  background-color: #f1f1f1;
}

.service-address.taobao-style .address-result .change-operation {
  display: none;
}

.next-checkbox-wrapper[dir="rtl"] {
  margin-right: 8px;
  margin-left: 0;
}

.next-checkbox-wrapper[dir="rtl"]:first-child {
  margin-right: 0;
}

.next-checkbox-wrapper[dir="rtl"]>.next-checkbox-label {
  margin-right: 4px;
  margin-left: 0;
}

.next-checkbox-wrapper,
.next-checkbox-wrapper *,
.next-checkbox-wrapper :after,
.next-checkbox-wrapper :before {
  box-sizing: border-box;
}

.next-checkbox-wrapper .next-checkbox {
  display: inline-block;
  position: relative;
  line-height: 1;
  vertical-align: middle;
}

.next-checkbox-wrapper input[type="checkbox"] {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 16px;
  height: 16px;
  margin: 0;
  cursor: pointer;
}

.next-checkbox-wrapper .next-checkbox-inner {
  display: block;
  width: 16px;
  height: 16px;
  background: #fff;
  border-radius: 3px;
  border: 1px solid #c4c6cf;
  transition: all 0.1s linear;
  text-align: left;
  box-shadow: none;
}

.next-checkbox-wrapper .next-checkbox-inner>.next-icon {
  transform: scale(0);
  position: absolute;
  top: 0;
  opacity: 0;
  line-height: 16px;
  transition: all 0.1s linear;
  color: #fff;
  left: 4px;
  margin-left: 0;
}

.next-checkbox-wrapper .next-checkbox-inner>.next-icon:before {
  width: 8px;
  font-size: 8px;
  line-height: inherit;
}
</style>
