<template>
  <div class="app-container">
    <el-card shadow="never">


      <!-- <div style="padding:10px 0px">
        <el-button type="primary" size="small" @click="setMarkupTemplate()">
          添加分站
        </el-button>
      </div>
 -->

      <el-table :data="tableData" style="width: 100%" border>
        <el-table-column prop="userId" label="用户编号" align="center">
        </el-table-column>
        <el-table-column prop="domains" label="分站地址" align="center">
        </el-table-column>
        <el-table-column prop="createTime" label="创建时间" align="center">
        </el-table-column>
      </el-table>

      <!-- 分页 -->
      <!-- <div style="float: right; margin: 16px 0">
        <pagination
          :total="total"
          :current-page="queryParam.pageNum"
          :page-size="queryParam.pageSize"
          @currentChange="handleCurrentChange"
          @editPagesizes="editPageSizes"
        />
      </div> -->
    </el-card>



     <!-- 添加分站 -->
     <el-dialog
      destroy-on-close
      append-to-body
      :visible.sync="insterVipVisible"
      class="success-dialog el_dialog_radius"
      width="40%"
    >

    </el-dialog>
  </div>
</template>
<script>
import { queryVipList } from "@/api/vip";
import { getToken } from "@/utils/auth";

export default {
  data() {
    return {
      insterVipVisible:false,
      tableData: [],
      queryParam: {
        pageNum: 1,
        pageSize: 10,
      },
    };
  },
  created() {
    this.verifyIsLogin();
  },
  methods: {
    /**
     * 判断用户是否登录
     */
    verifyIsLogin() {
      const hasToken = getToken();
      if (!hasToken) {
        this.$router.push("/plogin");
      } else {
        this.initData();
      }
    },

    initData() {
      //主站商品列表
      new Promise((resolve, rejust) => {
        queryVipList(this.queryParam)
          .then((res) => {
            //主站
            this.tableData = res.result.list;

            resolve();
          })
          .catch((error) => {
            rejust(error);
          });
      });
    },

    // 修改当前页事件
    handleCurrentChange(pageNum) {
      this.queryParam.pageNum = pageNum;
      this.initData();
    },

    // 修改分页的每页的条数
    editPageSizes(val) {
      this.queryParam.pageSize = val;
      this.initData();
    },
  },
};
</script>
