<template>
  <div v-loading.fullscreen.lock="fullscreenLoading">
    <el-card shadow="never">
      <!-- 条件查询 -->
      <div>
        <el-form :inline="true" :model="queryParam" class="demo-form-inline">
          <el-form-item label="商品名称">
            <el-input size="medium" v-model="queryParam.searchKeyword" placeholder="请输入商品名称"></el-input>
          </el-form-item>

          <el-form-item>
            <el-button type="primary" size="small" @click="handleQuery">
              查询
            </el-button>
          </el-form-item>
        </el-form>
      </div>

      <div class="table-operations">

        <el-button type="success" size="small" @click="setMarkupTemplate()">
          设置商品加价模版
        </el-button>


        <!-- <el-button type="success" size="small" @click="toAllProductMarkupUp()">
          一键加价商品
        </el-button>


        <el-button type="success" size="small" @click="toAllProductMarkupUp()">
          一键加价商品
        </el-button>

        <el-button slot="reference" type="success" size="small" @click="toAllProductCleanMarkupUp">
          清除商品加价
        </el-button> -->
        <!-- <div>
          <el-popconfirm
            title="确定一键清除商品加价吗？"
            @confirm="toAllProductCleanMarkupUp"
          >
            <el-button slot="reference" type="success" size="small">
              清除商品加价
            </el-button>
          </el-popconfirm>
        </div> -->

        <!-- <el-button type="primary" size="small" @click="clickBatchPrice()">
          批量修改加价
        </el-button> -->

        <el-button type="primary" size="small" @click="batchXiaProduct()">
          批量下架商品
        </el-button>

        <el-button type="primary" size="small" @click="batchShangProduct()">
          批量上架商品
        </el-button>
      </div>
      <el-table :data="productList" border style="width: 100%" tooltip-effect="dark"
        @selection-change="selectionChange">
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column align="center" prop="id" label="商品ID" width="80"></el-table-column>

        <el-table-column align="center" label="商品图片" width="100">
          <template slot-scope="scope">
            <el-image style="width: 50px; height: 50px" :src="scope.row.picUrl"></el-image>
          </template>
        </el-table-column>

        <el-table-column align="center" prop="productName" label="商品名称"></el-table-column>
        <el-table-column align="center" prop="price" label="商品单价(元)">
        </el-table-column>

        <el-table-column align="center" prop="productTypeText" label="商品类型">
        </el-table-column>

        <el-table-column align="center" label="商品状态">
          <template slot-scope="scope">
            <el-switch v-model="scope.row.isSelling" :active-value="1" :inactive-value="0"
              @change="clickUpdateVipProductStatus(index, scope.row)">
            </el-switch>
          </template>
        </el-table-column>

        <el-table-column align="center" prop="priceTypeText" label="加价类型">
        </el-table-column>

        <!-- <el-table-column align="center" prop="markupPrice" label="加价幅度">
        </el-table-column> -->
        <!-- <el-table-column fixed="right" label="操作" align="center">
          <template slot-scope="scope">
            <el-button @click="setPrice(scope.row)" type="text">设置价格</el-button>
          </template>
        </el-table-column> -->
      </el-table>
      <div style="float: right; margin: 16px 0">
        <el-pagination @current-change="currentChangeHandle" :current-page="queryParam.pageNum"
          @size-change="handleSizeChange" :page-sizes="[10, 20, 50, , 100]" :page-size="queryParam.pageSize"
          layout="total, sizes, prev, pager, next, jumper" :total="total" background>
        </el-pagination>
      </div>
    </el-card>

    <!-- 批量改价 -->
    <el-dialog title="设置价格" :visible.sync="dialogVisible" center width="70%" append-to-body>
      <div class="f_product">
        <el-form :model="batchPrice" label-position="top">
          <!-- <el-form-item label="操作类型">
            <el-radio-group v-model="batchPrice.operateType">
              <el-radio label="1">批量加价</el-radio>
              <el-radio label="2">批量减价</el-radio>
            </el-radio-group>
          </el-form-item> -->

          <el-form-item label="加价类型">
            <el-radio-group v-model="batchPrice.priceType">
              <el-radio label="1">固定金额</el-radio>
              <el-radio label="2">百分比</el-radio>
            </el-radio-group>
          </el-form-item>

          <el-form-item label="加价幅度">
            <el-input v-model="batchPrice.price" placeholder="增加或者减少的金额/百分比"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onBatchPrice">确定</el-button>
            <el-button @click="dialogVisible = false">取消</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>

    <!-- 一键设置 -->
    <el-dialog title="设置商品加价" :visible.sync="dialogAllVisible" center width="70%" append-to-body>
      <div>
        <el-form :model="batchPrice" label-position="top">
          <!-- <el-form-item label="操作类型">
            <el-radio-group v-model="batchPrice.operateType">
              <el-radio label="1">批量加价</el-radio>
              <el-radio label="2">批量减价</el-radio>
            </el-radio-group>
          </el-form-item> -->

          <el-form-item label="加价类型">
            <el-radio-group v-model="batchPrice.priceType">
              <el-radio label="1">固定金额</el-radio>
              <el-radio label="2">百分比</el-radio>
            </el-radio-group>
          </el-form-item>

          <el-form-item label="加价幅度">
            <el-input v-model="batchPrice.price" placeholder="增加或者减少的金额/百分比"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="submitAllProductMarkupUp()">确定</el-button>
            <el-button @click="dialogAllVisible = false">取消</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>


    <!-- 设置加价模版 -->
    <el-dialog title="一键设置价格" :visible.sync="dialogMarkupTemplateVisible" center width="70%" append-to-body>
      <div>
        <el-form label-position="left" label-width="120px">

          <el-form-item label="加价规则">
            <el-select v-model="priceTemplateForm.priceType" placeholder="请选择加价规则" style="width:100%">
              <el-option label="按固定金额增加" :value="1"></el-option>
              <el-option label="按百分比增加" :value="2"></el-option>
            </el-select>
          </el-form-item>
          <el-divider></el-divider>
          
          <el-form-item v-for="(i, index) in levelList" :key="index" :label="i.levelName">
            <el-input v-model="i.levelMarkup"></el-input>
          </el-form-item>

          <el-form-item>
            <el-button type="primary" @click="insterPriceTemplate()">确定</el-button>
            <el-button @click="dialogMarkupTemplateVisible = false">取消</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { Message } from "element-ui";

import "@/assets/pc/css/style.css";
import "@/assets/pc/css/common.css";
import {
  queryProductList,
  updateVipProductPrice,
  updateVipProductStatus,
  allProductMarkupUp,
  allProductCleanMarkupUp,
  batchUpdateVipProductStatus,
  // syncMainProduct,
  // deleteProduct,
  // batchProductPrice,
  getLevelPriceTemplate,
} from "@/api/vip";
import { getToken } from "@/utils/auth";

import { getLevelList } from "@/api/user";

export default {
  data() {
    return {
      priceTemplateForm: {
        priceType: "",
      },
      levelList: [],
      dialogMarkupTemplateVisible: false,

      fullscreenLoading: false,
      dialogAllVisible: false,
      batchPrice: {
        //操作类型
        operateType: "1",
        //价格类型
        priceType: "1",
        //改价金额
        price: "",
        //商品ID
        productIds: [],
      },
      dialogVisible: false,
      total: 0,

      productList: [],
      selectedRowKeys: [],

      queryParam: {
        pageNum: 1,
        pageSize: 10,
        searchKeyword: undefined,
      },
    };
  },
  created() {
    this.verifyIsLogin();

    this.queryMemberLevelList();
  },
  methods: {
    /**
     * 判断用户是否登录
     */
    verifyIsLogin() {
      const hasToken = getToken();
      if (!hasToken) {
        this.$router.push("/plogin");
      } else {
        this.initLoad();
      }
    },

    /**
  * 查询平台等级列表
  */
    queryMemberLevelList() {
      new Promise((resolve, rejust) => {
        getLevelList({})
          .then((res) => {
            this.levelList = res.result;
            resolve();
          })
          .catch((error) => {
            rejust(error);
          });
      });
    },

    /**
     * 设置商品加价模版
     */
    setMarkupTemplate() {

      this.dialogMarkupTemplateVisible = true;

      //获取加价模版 
      new Promise((resolve, rejust) => {
        getLevelPriceTemplate(this.priceTemplateForm)
          .then((res) => {
            console.log(res.result)
            if(res.result){
              this.levelList = res.result.levelPriceList;

              this.priceTemplateForm.priceType = res.result.priceType
            }

            resolve();
          })
          .catch((error) => {
            this.fullscreenLoading = false;

            rejust(error);
          });
      });
    },

    /**
     * 保存模版
     */
    insterPriceTemplate() {
      this.priceTemplateForm.levelPriceList = this.levelList;

      new Promise((resolve, rejust) => {
        allProductMarkupUp(this.priceTemplateForm)
          .then((res) => {
            console.log(res);
            Message({
              message: "设置成功",
              type: "success",
              duration: 5 * 1000,
            });
            this.dialogMarkupTemplateVisible = false;

            this.initLoad();

            resolve();
          })
          .catch((error) => {
            this.fullscreenLoading = false;

            rejust(error);
          });
      });
    },

    /**
     * 一键删除加价
     */
    toAllProductCleanMarkupUp() {
      this.fullscreenLoading = true;

      this.$confirm("确认一键清除加价吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          new Promise((resolve, rejust) => {
            allProductCleanMarkupUp({})
              .then((res) => {
                console.log(res);
                Message({
                  message: "删除成功",
                  type: "success",
                  duration: 5 * 1000,
                });
                this.fullscreenLoading = false;
                this.initLoad();

                resolve();
              })
              .catch((error) => {
                this.fullscreenLoading = false;
                rejust(error);
              });
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
          this.fullscreenLoading = false;
        });
    },

    /**
     * 一键设置未设置加价商品
     */
    toAllProductMarkupUp() {
      this.dialogAllVisible = true;
    },

    /**
     * 一键加价
     */
    submitAllProductMarkupUp() {
      this.fullscreenLoading = true;

      new Promise((resolve, rejust) => {
        allProductMarkupUp(this.batchPrice)
          .then((res) => {
            console.log(res);
            Message({
              message: "设置成功",
              type: "success",
              duration: 5 * 1000,
            });
            this.dialogAllVisible = false;
            this.fullscreenLoading = false;

            this.initLoad();

            resolve();
          })
          .catch((error) => {
            this.fullscreenLoading = false;

            rejust(error);
          });
      });
    },

    //分站
    initLoad() {
      //分站商品列表
      new Promise((resolve, rejust) => {
        queryProductList(this.queryParam)
          .then((res) => {
            //分站
            this.productList = res.result.list;
            this.total = res.result.total;
            this.selectedRowKeys = [];
            this.fullscreenLoading = false;

            resolve();
          })
          .catch((error) => {
            rejust(error);
          });
      });
    },

    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParam.pageNum = 1;
      this.initLoad();
    },

    /**
     * 设置价格
     */
    setPrice(row) {
      this.dialogVisible = true;
      this.batchPrice.productIds = [];
      this.batchPrice.priceType = "1";
      this.batchPrice.price = "";

      this.batchPrice.productIds.push(row.id);
    },

    //主站商品点击分页
    currentChangeHandle(current) {
      this.queryParam.pageNum = current;
      this.initLoad();
    },

    //切换页数
    handleSizeChange(val) {
      this.queryParam.pageSize = val;
      this.initLoad();
    },

    //主站选中商品
    selectionChange(v) {
      var listData = [];
      const items = v;
      for (let i = 0; i < items.length; i++) {
        listData.push(items[i].id);
      }
      this.selectedRowKeys = listData;
    },

    /**
     * 修改分站商品状态
     */
    clickUpdateVipProductStatus(index, row) {
      console.log(index, row);
      const ids = [row.id];
      let data = {
        productIds: ids,
      };
      this.fullscreenLoading = true;

      new Promise((resolve, rejust) => {
        updateVipProductStatus(data)
          .then((res) => {
            Message({
              message: res.message || "Error",
              type: "success",

              duration: 5 * 1000,
            });

            //同步成功后刷新商品列表
            this.initLoad();
            resolve();
          })
          .catch((error) => {
            this.fullscreenLoading = false;

            rejust(error);
          });
      });
    },

    /**
     * 批量下架商品
     */
    batchXiaProduct() {
      if (this.selectedRowKeys.length <= 0) {
        Message({
          message: "最少选中一条记录",
          type: "warning",
          duration: 5 * 1000,
        });
        return;
      }
      this.fullscreenLoading = true;

      new Promise((resolve, rejust) => {
        let data = {
          productIds: this.selectedRowKeys,
          status: 0,
        };
        batchUpdateVipProductStatus(data)
          .then((res) => {
            Message({
              message: res.message || "Error",
              type: "success",
              duration: 5 * 1000,
            });

            //同步成功后刷新商品列表
            this.initLoad();
            resolve();
          })
          .catch((error) => {
            this.fullscreenLoading = false;
            rejust(error);
          });
      });
    },

    /**
     * 批量上架商品
     */
    batchShangProduct() {
      if (this.selectedRowKeys.length <= 0) {
        Message({
          message: "最少选中一条记录",
          type: "warning",
          duration: 5 * 1000,
        });
        return;
      }
      this.fullscreenLoading = true;

      new Promise((resolve, rejust) => {
        let data = {
          productIds: this.selectedRowKeys,
          status: 1,
        };
        batchUpdateVipProductStatus(data)
          .then((res) => {
            Message({
              message: res.message || "Error",
              type: "success",
              duration: 5 * 1000,
            });
            //同步成功后刷新商品列表
            this.initLoad();
            resolve();
          })
          .catch((error) => {
            this.fullscreenLoading = false;

            rejust(error);
          });
      });
    },

    // //同步主站商品
    // synProduct() {
    //   if (this.selectedRowKeys.length <= 0) {
    //     Message({
    //       message: "最少选中一条记录",
    //       type: "warning",
    //       duration: 5 * 1000,
    //     });
    //     return;
    //   }
    //   console.log("===========");
    //   new Promise((resolve, rejust) => {
    //     let data = {
    //       idList: this.selectedRowKeys,
    //     };
    //     syncMainProduct(data)
    //       .then((res) => {
    //         Message({
    //           message: res.message || "Error",
    //           type: "success",
    //           duration: 5 * 1000,
    //         });
    //         //同步成功后刷新商品列表
    //         this.load();
    //         resolve();
    //       })
    //       .catch((error) => {
    //         rejust(error);
    //       });
    //   });
    // },

    // //下架商品
    // xjProduct() {
    //   new Promise((resolve, rejust) => {
    //     let data = {
    //       idList: this.selectedRowKeys,
    //     };
    //     deleteProduct(data)
    //       .then((res) => {
    //         Message({
    //           message: res.message || "Error",
    //           type: "success",
    //           duration: 5 * 1000,
    //         });
    //         //同步成功后刷新商品列表
    //         this.initLoad();
    //         resolve();
    //       })
    //       .catch((error) => {
    //         rejust(error);
    //       });
    //   });
    // },

    //批量改价弹框
    clickBatchPrice() {
      if (this.selectedRowKeys.length <= 0) {
        Message({
          message: "最少选中一条记录",
          type: "warning",
          duration: 5 * 1000,
        });
        return;
      }
      this.dialogVisible = true;
      this.batchPrice.productIds = [];
      this.batchPrice.priceType = "1";
      this.batchPrice.price = "";
    },

    //商品改价
    onBatchPrice() {
      if (this.selectedRowKeys.length > 0) {
        this.batchPrice.productIds = this.selectedRowKeys;
      }
      this.updateProductPrice();
    },

    updateProductPrice() {
      new Promise((resolve, rejust) => {
        updateVipProductPrice(this.batchPrice)
          .then((res) => {
            Message({
              message: res.message || "Error",
              type: "success",

              duration: 5 * 1000,
            });
            this.dialogVisible = false;

            //同步成功后刷新商品列表
            this.initLoad();
            resolve();
          })
          .catch((error) => {
            rejust(error);
          });
      });
    },

    // //修改商品
    // updateClick(){
    //   this.dialogUpdateProductVisible = true
    // },
  },
};
</script>
